/*footer */
.custom_text_top_footer {
  margin-bottom: 53px;
  .title {
    font-size: 20px;
    letter-spacing: 6px;
  }
  .desc {
    font-size: 80px;
    color: @crt_global_cl;
    font-weight: 300;
    margin-top: -15px;
  }
}

.tzFooterSocial {
  padding: 36px 0 25px;
  ul {
    margin: 0;
    padding: 0;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    li {
      float: left;
      padding: 0 8px;
      a {
        position: relative;
        text-align: center;
        display: inline-block;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: transparent;
        &:after {
          background: @crt_global_cl;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          content: '';
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          -webkit-transform: scale(0);
          -moz-transform: scale(0);
          -ms-transform: scale(0);
          -o-transform: scale(0);
          transform: scale(0);
          .transition(all 0.3s ease-in-out 0s);
        }
        span {
          .transition(all 0.3s ease-in-out 0s);
          font-size: 32px;
          color: #6d6d6d;
          &:hover {
            color: @crt_global_cl;
          }
        }
      }
    }
  }
  &.style-2 {
    padding: 25px 0;
    li {
      padding: 0 5px;
      a {
        background: #313131;
        border-radius: 50%;
        display: inline-block;
        height: 40px;
        position: relative;
        text-align: center;
        width: 40px;
        &:after {
          background: @crt_global_cl;
          border-radius: 50%;
          content: "";
          display: block;
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          .scale(0);
          .transition(all 0.3s ease-in-out 0s);
          width: 100%;
        }
        i {
          color: #868686;
          font-size: 15px;
          padding: 0;
          position: relative;
          .transition(all 0.3s ease-in-out 0s);
          z-index: 99;
          line-height: 40px;
        }
        &:hover {
          &:after {
            opacity: 1;
            .scale(1);
          }
          i {
            color: @white;
          }
        }
      }
    }
  }
}

.tzCopyright {
  padding: 40px 0 33px;
  p {
    color: #9b9b9b;
    margin: 0;
  }
  a {
    color: #9b9b9b;
    &:hover {
      color: @crt_global_cl;
    }
  }
  span {
    color: @crt_global_cl;
  }
}

.tzheader_social {
  li {
    float: left;
    padding: 12px 0 10px 26px;
    line-height: 24px;
    a {
      color: @crt_title_cl;
      font-size: 15px;
      &:hover {
        color: @crt_global_cl;
      }
    }
  }
}

/* Blog */
#k2Container {
  &.itemView,
  &.itemListView {

    .tz_padding_item {
      margin: 0 -15px;
      .itemContainer {
        padding: 0 15px;
      }
    }
    .catItemView {
      background: @white;
      margin-bottom: 50px;
      padding: 0;
    }
    .tz_blog_item_content {
      padding: 48px 30px 45px;
    }
    .catItemHeader {
      .catItemImage,
      .catItemImageBlock {
        padding: 0;
        margin: 0;
      }
    }
    .tz_information {
      margin: 0 0 22px;
      > * {
        display: inline-block;
        color: #b5b5b5;
        font-style: italic;
        line-height: 22px;
        margin: 0;
        border: none;
        padding: 0;
        &:after {
          content: '|';
          position: relative;
          font-style: italic;
          margin-right: 5px;
          margin-left: 3px;
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
      }
      a {
        color: #b5b5b5;
        &:hover {
          color: @crt_global_cl;
        }
      }
    }
    .itemTitle {
      font-size: 20px;
      font-weight: 400;
      line-height: 27px;
      margin: 0 0 16px;
    }
    .catItemTitle {
      margin: 0 0 17px;
      padding: 0;
      .tz_item_featured {
        font-size: 14px;
      }
    }
    .itemRatingForm {
      display: inline-block;
      float: none;
    }
    .catItemRatingBlock {
      span {
        display: inline-block;
        float: none;
        font-style: italic;
      }
    }
    .catItemBody {
      padding: 0;
      margin-bottom: 25px;
      > * {
        margin-bottom: 10px;
      }
      .catItemIntroText {
        color: #666666;
        line-height: 25px;
        padding: 0;
      }
    }
    .catItemReadMore {
      display: block;
      .k2ReadMore {
        background: @crt_global_cl;
        border-radius: 3px;
        color: #ffffff;
        display: inline-block;
        line-height: 25px;
        padding: 8px 27px;
        text-decoration: none;
      }
    }
    .catItemDateModified {
      padding: 15px 0 0;
      margin: 25px 0 0;
    }
  }
  &.itemView {
    padding: 0;
    margin: 0;
    .itemToolbar {
      display: block !important;
      position: absolute;
      top: 30px;
      right: 30px;
      .dropdown-toggle {
        background: transparent none repeat scroll 0 0;
        border: medium none;
        padding: 0;
      }
      li {
        display: block;
        border: none;
        text-align: left;
        a {
          display: inline-block;
          padding: 0;
        }
      }
    }
    .itemImage {
      margin: 0;
    }
    .itemAuthorLatest,
    .itemRelated,
    .itemNavigation,
    .itemAuthorBlock,
    .tz_blog_item_content {
      background: @white;
      margin-bottom: 30px;
      position: relative;
    }
    .tz_information {
      border-bottom: 1px solid #ededed;
      display: block;
      margin: 0 0 27px;
      padding: 0 0 28px;
    }
    .itemIntroText {
      font-size: 100%;
    }
    .itemImageBlock {
      margin: 0;
      padding: 0;
    }
    .tz_blog_item_content {
      padding: 35px 32px;
    }
    .itemAuthorBlock {
      padding: 30px;
    }
    .itemBody {
      border-bottom: 1px solid #ededed;
      margin: 0 0 25px;
      padding: 0 0 32px;
    }
    .bottom {
      &:after {
        content: '';
        clear: both;
        display: block;
      }
    }
    .itemTagsBlock {
      float: left;
      a {
        color: #666666;
        font-weight: 300;
      }
    }
    .itemSocialSharing {
      float: right;
      padding: 0;
      > span {
        float: left;
      }
      > * {
        width: auto;
        margin: 0 0 0 6px;
        display: inline-block;
      }
    }
    .itemTagsBlock > span,
    .itemSocialSharing > span {
      color: #222222;
      font-weight: 700;
      line-height: 25px;
      margin: 0 8px 0 0;
    }
    .itemAuthorBlock {
      border: none;
      .tz-table-cell {
        vertical-align: middle;
      }
      .itemAuthorAvatar {
        float: none;
        padding: 0;
        margin: 0;
        height: 140px;
        width: 140px;
        overflow: hidden;
        border-radius: 50%;
        img {
          width: 100%;
        }
      }
      .itemAuthorDetails {
        padding: 0 0 0 40px;
        .itemAuthorName {
          margin: 0;
          color: @crt_title_cl;
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          text-decoration: none;
          text-transform: uppercase;
        }
        p {
          color: #666666;
          font-weight: 300;
          line-height: 22px;
        }
      }
    }
    .itemAuthorLatest,
    .itemRelated,
    .itemNavigation {
      padding: 30px;
      h3 {
        margin-top: 0;
      }
    }
    .itemRelated {
      ul {
        margin: 0;
        li.k2ScrollerElement {
          margin: 0 10px 5px 0;
          padding: 0;
          position: relative;
          &:before {
            content: '';
            background: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
          }
          img {
            max-width: none;
            position: relative;
          }
          .tz_align_center {
            z-index: 1;
            right: 10px;
            left: 10px;
            > * {
              color: @white;
              display: block;
              text-align: center;
            }
          }
        }
      }
    }
    .itemComments {
      border: none;
      background: transparent;
      padding: 0;
      border-radius: 0;
      .itemCommentsForm,
      .listComments {
        padding: 30px;
        background: @white;
        margin-bottom: 30px;
      }
      .itemCommentsCounter {
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        margin: 0 0 42px;
        padding: 0;
        text-transform: uppercase;
      }
      .listComments {
        ul.itemCommentsList li {
          padding: 0 0 30px;
          margin: 0 0 30px;
          .tz-content {
            padding-left: 30px;
            width: 100%;
          }
          .tz-info {
            margin-bottom: 12px;
          }
          .commentAuthorName {
            color: @crt_title_cl;
            font-weight: 400;
            line-height: 22px;
            text-decoration: none;
            text-transform: uppercase;
            margin-right: 10px;
          }
          .commentDate {
            color: #b5b5b5;
            font-style: italic;
            font-weight: 300;
            line-height: 22px;
            border: none;
          }
          .commentLink,
          .commentToolbar {
            display: inline-block;
            float: right;
            padding: 0 5px;
          }
          .tz-image {
            width: 90px;
            img {
              padding: 0;
              margin: 0;
              float: none;
            }
          }
        }
      }
      .itemCommentsForm {
        > h3 {
          text-transform: uppercase;
        }
        .itemCommentsFormNotes {
          padding: 0;
          border: none;
          margin: 0 0 50px;
        }
        #submitCommentButton {
          margin: auto;
          background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
          border-color: @crt_global_cl !important;
          color: @crt_global_cl !important;
          font-size: 12px;
          font-weight: 300;
          line-height: 22px !important;
          padding: 5px 15px !important;
          text-transform: uppercase;
          border-radius: 0;
        }
        #comment-form {
          label {
            display: none;
          }
          textarea, input {
            color: #b5b5b5;
            border-width: 0 0 1px;
            border-style: solid;
            border-color: #ededed;
            .box-shadow(none);
            font-weight: 300;
            line-height: 24px;
            padding: 5px 0;
          }
          textarea {
            margin-bottom: 43px;
            height: 95px;
          }
          input {
            margin-bottom: 37px;
          }
        }
      }
    }
  }
  .k2Pagination {
    text-align: left;
    padding: 0;
    margin: 0;

  }
}

body {
  ul.pagination {
    margin: 0;
    li {
      display: inline-block;
      border-radius: 0 !important;
      &.active {
        a {
          color: @white !important;
          background: @crt_global_cl !important;
        }
      }
      a {
        display: block;
        padding: 0;
        margin: 0 5px;
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        background: #dfdfdf !important;
        color: @color_666 !important;
        float: none;
        border: none;

      }
    }
  }
  .hikashop_products_pagination {
    .pagination {
      li {
        a:hover,
        &.active a {
          border-color: @crt_global_cl !important;
          background: @crt_global_cl !important;
          color: @white !important;
        }
        a {
          background: @white !important;
          border: 1px solid #dedede !important;
          font-weight: 400;
        }
      }
    }
  }
}

/* End Blog */

/* Blog Masonry*/
.layout_masonry {
  div.itemContainer {
    float: none;
  }
  .catItemView {
    margin: 0 !important;
  }
  .itemList {
    margin: -15px;
    .tz_inner {
      padding: 15px;
    }
  }
  .k2Pagination {
    padding-top: 30px !important;
  }
}

/* End Blog Masonry*/

/* Filter */
.ui-slider {
  background: @white;
  height: 7px;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 100% !important;
  margin: 0 !important;
}

.ui-slider-range {
  background: @crt_global_cl;
  height: 7px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
}

.ui-slider-handle {
  height: 14px;
  width: 14px;
  background: @white;
  position: absolute;
  top: -4px;
  border-radius: 50%;
  margin-left: -5px;
  border: 3px solid @crt_global_cl;
}

/* End Filter */

/* Contact */
.contact {
  padding: 40px 37px;
  #contact-form {
    .btn {
      border-radius: 3px;
      padding: 10px 25px;
      letter-spacing: 1px;
      &:hover {
        background: @crt_global_cl;
      }
    }
    .control-group {
      margin-bottom: 10px;
    }
    label {
      font-weight: 300;
    }
    textarea {
      min-height: 100px;
    }
    input[type="text"],
    input[type="email"], textarea {
      border-color: #e3e3e3;
      border-image: none;
      border-style: none none solid;
      border-width: medium medium 1px;
      font-size: 14px;
      height: 45px;
      line-height: 45px;
      margin-bottom: 10px;
      width: 100%;
    }
    input[type="checkbox"] {
      position: relative;
      top: 1px;
      margin-right: 10px;
      left: 0;
    }
    .email_copy {
      margin-bottom: 10px;
      > * {
        display: inline-block;
      }
    }
  }
}

.contact-profile,
.contact-articles,
.contact-links {
  padding-bottom: 44px;
}

.contact-address {
  li {
    padding-top: 0;
    padding-bottom: 44px;
    .contact-telephone,
    .contact-fax,
    .contact-mobile,
    span {
      letter-spacing: 0.5px;
    }
    .webpage, .email,
    .telephone, .fax, .mobile {
      margin-bottom: 10px;
    }
    a {
      color: @color_666;
    }
  }
  .jicons-icons {
    min-width: 20px;
  }
  .webpage .jicons-icons,
  .email .jicons-icons,
  .telephone .jicons-icons,
  .fax .jicons-icons,
  .mobile .jicons-icons,
  .jicons-icons.address {
    img {
      display: none;
    }
    &:before {
      font-family: FontAwesome;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .webpage .jicons-icons:before {
    content: '\f0ac';
  }

  .email .jicons-icons:before {
    content: '\f003';
  }
  .jicons-icons.address:before {
    content: '\f041';
  }
  .telephone .jicons-icons:before {
    content: '\f095';
  }
  .mobile .jicons-icons:before {
    content: '\f10b';
  }
  .fax .jicons-icons:before {
    content: '\f1ac';
  }
}

/* Login*/
.login .form-validate {
  border-color: #e1e5e7;
  .box-shadow(none);
  border-radius: 0;
}

.k2AccountPage,
.hikashop_user_registration_page,
.login {
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='url'],
  input[type='password'],
  textarea {
    border: 1px solid #ededed;
    width: 100%;
    .box-shadow(none);
    padding: 10px 15px 10px 15px;
    margin: 10px 0;
  }
  button[type="submit"] {
    margin-top: 15px;
  }
  .chzn-container {
    width: 100% !important;
    margin: 10px 0;
  }
  .nav-stacked {
    li {
      display: inline-block;
    }
  }
  .gender {
    .radio {
      display: inline-block;
      margin-right: 5px;
      input {
        position: relative;
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
  td.key {
    border: none !important;
    background: transparent !important;
    text-align: left !important;
    font-size: 13px !important;
    font-weight: 500 !important;
  }
}

/*Search K2 Result*/
.genericView {
  .componentheading {
  }
}

.genericItemView {
  .genericItemBody {
    padding: 0 0 0 30px;
    .genericItemTitle {
      margin: 0 0 17px;
      padding: 0;
    }
    .tz_info {
      > * {
        display: inline-block;
        border-right: 1px solid #ededed;
        margin: 0 5px 0 0;
        padding: 0 5px 0 0;
        &:last-child {
          border: none;
          margin: 0;
          padding: 0;
        }
      }
      color: #b5b5b5 !important;
      font-weight: 300 !important;
      font-style: italic !important;
    }
  }
}

/*About Us*/
.tz_about_us {
  .item-image {
    float: none !important;
    margin-bottom: 10px;
  }
  .content_article {
    padding: 30px 30px 0;
    p {
      margin-bottom: 23px;
    }
  }
}

/* Light Box */
#sbox-window {
  iframe {
    max-width: 100%;
    max-height: 100%;

  }
}

body.contentpane.modal {
  .close {
    background: @white;
    border: 1px solid #dedede;
    padding: 6px 10px;
  }
  .alert-block {
    p {
      display: inline-block;
      padding-left: 15px;
    }
  }
  #hikashop_add_to_cart_checkout_div,
  #hikashop_add_to_cart_continue_div {
    display: inline-block;
    a {
      display: block;
      padding: 5px 10px;
      background: @crt_title_cl;
      color: @white;
      &:hover {
        background: @crt_global_cl;
      }
    }
  }
}

.popup_content {
  right: 0;
  left: 0;
  outline: medium none !important;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1043;
  overflow-x: hidden;
  overflow-y: auto;
  background: #252525;
  //opacity: 0;
  //visibility: hidden;
  .transition(all 0.3s ease 0s);
  &.loaded {
    .tz_content > .container {
      opacity: 1;
      .scale(1);
    }
    .tz_content > .loading {
      opacity: 0;
      visibility: hidden;
    }
  }
  .tz_detail {
    margin-bottom: 45px;
  }
  .tzPortfolio_Single_tks {
    color: @crt_global_cl;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-top: 14px;
    text-align: center;
    text-transform: uppercase;
  }
  .tz_content {
    padding: 50px 0 85px;
    > .container {
      .scale(0.5);
      opacity: 0;
      .transition(all 0.3s ease 0s);
    }
    .loading {
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 50%;
      .translateY(-50%);
      opacity: 1;
      visibility: visible;
      .transition(all 0.3s ease 0s);
    }
  }
  .tzPortfolio_Single_Media {
    margin-bottom: 65px;
    img {
      width: 100%;
    }
  }
  .tzPortfolio_Single_Content {
    * {
      color: @white;
    }
  }
  .tzPortfolio_Single_Info {
    ul li {
      color: @crt_global_cl;
      padding: 0;
      line-height: 25px;
      font-weight: 300;
      label {
        min-width: 100px;
        color: @white;
        font-weight: 400;
        margin: 0;
      }
    }
  }
  .tzPortfolio_Single_title {
    color: @crt_global_cl;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 48px 0 44px;
    text-align: center;
    text-transform: uppercase;
  }
}

.tzPortfolio_Single_Close {
  display: block;
  text-align: center;
  i {
    color: @white;
    font-size: 30px;
    font-weight: bold;
    height: auto;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: relative;
    transition: all 0.3s ease-in-out 0s;
    width: auto;
    &:hover {
      color: @crt_global_cl;
    }
  }
}

/*Page User*/
.genericItemList .genericItemView,
.tagItemList .tagItemView,
.userItemList .userItemView {
  background: @white;
  padding: 30px;
  margin-bottom: 30px;
}

.itemListCategoriesBlock {

  .tz_info {
    padding-left: 30px;
    > div {
      line-height: 1.5em;
      letter-spacing: 0.2px;
      font-weight: 300;
    }
  }
  .itemListCategory {
    background: @white;
    border-radius: 0;
    border-color: #e1e5e7;
  }
}

/* Portfolio */
.tz_portfolio_plus_articles {
  .TzPortfolioReadmore {
    &:before {
      content: '\f08e';
      font-family: FontAwesome;
      margin-right: 3px;
    }
  }
}

.TzContent {
  .muted {
    color: @white;
    letter-spacing: 2px;
    a {
      color: @white;
      &:hover {
        color: @crt_global_cl;
      }
    }
  }
  .tz_room_tag {
    font-weight: 300;
    a {
      color: @white;
    }
  }
  #portfolio {
    margin: 0;
  }
  .option-combo {
    display: inline-block;
  }
  #tz_options {
    background: #252525;
    margin-bottom: 1px;
    padding: 20px 0 7px;
    a {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      color: @color_666;
      display: inline-block;
      float: left;
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 10px;
      margin-left: -1px;
      padding: 13px 25px;
      text-decoration: none;
      .transition(all 0.3s ease-in-out 0s);
      &.selected,
      &:hover {
        background: @crt_global_cl;
        color: @white;
        border-right-color: @crt_global_cl;
      }
    }
  }
  #tz_append {
    background: #252525;
    a {
      margin-top: 15px;
      display: inline-block;
      margin-bottom: 15px;
      color: @white;
      &:hover {
        text-decoration: none;
        color: @crt_global_cl;
      }
    }
  }
  #infscr-loading {
    background: transparent;
    div {
      color: @white;
      display: inline-block;
      margin: 15px 0;
    }
  }
  .tz_item .TzInner {
    padding: 0;
    margin: 0;
    border-left: none;
    border-top: none;
    border-color: @white;
    overflow: hidden;
    &:hover {
      .TzPortfolioDescription::before {
        background: rgba(0, 0, 0, 0.8);
      }
      .TzArticleMedia {
        img {
          .scale(1.1);
        }
      }
    }
    .TzPortfolioDescription {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      &:before {
        background: rgba(0, 0, 0, 0.5);
        bottom: 1px;
        content: "";
        left: 0;
        position: absolute;
        right: 1px;
        top: 0;
        .transition(all 0.3s ease-in-out 0s);
        z-index: -1;
      }
    }
    .TzPortfolioTitle {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.5px;
      margin: 0 0 6px;
      text-transform: uppercase;
      a {
        color: @crt_global_cl;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .TzPortfolioReadmore {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
      overflow: hidden;
      text-transform: uppercase;
      .transition(all 0.5s ease-in-out 0s);
      display: inline-block;
      position: relative;
      color: @white;
      &:hover {
        color: @crt_global_cl;
        text-decoration: none;
        &:after {
          left: 100%;
        }
      }
      &:after {
        background: @crt_global_cl;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: -100%;
        position: absolute;
        .transition(all 0.5s ease-in-out 0s);
        width: 100%;
      }
    }
    .tz_tags {
      letter-spacing: 2px;
      color: @white;
      a {
        color: @white;
        font-weight: 300;
        &:hover {
          text-decoration: none;
          color: @crt_global_cl;
        }
      }
    }
    .TzArticleMedia {
      margin: 0;
      img {
        max-width: none;
        position: relative;
        .transition(all 0.5s ease-in-out 0s);
      }
    }
    .top {
      top: 32px;
    }
    .bottom {
      bottom: 32px;
    }
    .bottom, .top, .middle {
      right: 30px;
      position: absolute;
      left: 30px;
    }
  }
}

#TzContent {

  .muted {
    color: @white;
    letter-spacing: 2px;
    a {
      color: @white;
      &:hover {
        color: @crt_global_cl;
      }
    }
  }
  .tz_room_tag {
    font-weight: 300;
    a {
      color: @white;
    }
  }
  #portfolio {
    margin: 0;
  }
  .option-combo {
    display: inline-block;
  }
  #tz_options {
    background: #252525;
    margin-bottom: 1px;
    padding: 20px 0 7px;
    a {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      color: @color_666;
      display: inline-block;
      float: left;
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 10px;
      margin-left: -1px;
      padding: 13px 25px;
      text-decoration: none;
      .transition(all 0.3s ease-in-out 0s);
      &.selected,
      &:hover {
        background: @crt_global_cl;
        color: @white;
        border-right-color: @crt_global_cl;
      }
    }
  }
  #tz_append {
    background: #252525;
    a {
      margin-top: 15px;
      display: inline-block;
      margin-bottom: 15px;
      color: @white;
      &:hover {
        text-decoration: none;
        color: @crt_global_cl;
      }
    }
  }
  #infscr-loading {
    background: transparent;
    div {
      color: @white;
      display: inline-block;
      margin: 15px 0;
    }
  }
  .tz_item .TzInner {
    padding: 0;
    margin: 0;
    border-left: none;
    border-top: none;
    border-color: @white;
    overflow: hidden;
    &:hover {
      .TzPortfolioDescription::before {
        background: rgba(0, 0, 0, 0.8);
      }
      .TzArticleMedia {
        img {
          .scale(1.1);
        }
      }
    }
    .TzPortfolioDescription {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      &:before {
        background: rgba(0, 0, 0, 0.5);
        bottom: 1px;
        content: "";
        left: 0;
        position: absolute;
        right: 1px;
        top: 0;
        .transition(all 0.3s ease-in-out 0s);
        z-index: -1;
      }
    }
    .TzPortfolioTitle {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.5px;
      margin: 0 0 6px;
      text-transform: uppercase;
      a {
        color: @crt_global_cl;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .TzPortfolioReadmore {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
      overflow: hidden;
      text-transform: uppercase;
      .transition(all 0.5s ease-in-out 0s);
      display: inline-block;
      position: relative;
      color: @white;
      &:hover {
        color: @crt_global_cl;
        text-decoration: none;
        &:after {
          left: 100%;
        }
      }
      &:after {
        background: @crt_global_cl;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: -100%;
        position: absolute;
        .transition(all 0.5s ease-in-out 0s);
        width: 100%;
      }
    }
    .tz_tags {
      letter-spacing: 2px;
      color: @white;
      a {
        color: @white;
        font-weight: 300;
        &:hover {
          text-decoration: none;
          color: @crt_global_cl;
        }
      }
    }
    .TzArticleMedia {
      margin: 0;
      img {
        max-width: none;
        position: relative;
        .transition(all 0.5s ease-in-out 0s);
      }
    }
    .top {
      top: 32px;
    }
    .bottom {
      bottom: 32px;
    }
    .bottom, .top, .middle {
      right: 30px;
      position: absolute;
      left: 30px;
    }
  }
}

.fancybox-opened {
  z-index: 9999;
}

.fancybox-skin {
  background: #252525;
}

.contentpane.tzmodal {
  background: #252525;
  color: @white;
  font-family: 'Ubuntu', sans-serif;
  h2, h1, h3, h4, h5, h6 {
    font-family: "Montserrat", sans-serif;
    color: @white;
  }
  .button_close_lightbox {
    display: block;
  }
  .TzItemPage {
    padding: 50px 0 85px;
    .TzArticleTitle {
      color: @crt_global_cl;
      font-size: 30px;
      font-weight: 700;
      letter-spacing: 1px;
      margin: 48px 0 44px;
      text-align: center;
      text-transform: uppercase;
    }
  }
  li,
  p {
    color: @white;
  }
  .tz-label {
    min-width: 100px;
    color: @white;
  }
  .tz-value {
    color: @crt_global_cl;
    * {
      color: @crt_global_cl;
    }
    a:hover {
      color: @white;
    }
  }
  .TzArticleTag {
    margin: 0;
  }
  .tz_infos {
    .tz_info {
      > * {
        line-height: 25px;
        display: block;
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
  .TzArticleMedia {
    margin: 0;
    img {
      width: 100%;
    }
  }
  .tz_portfolio_plus_user {
    .media-body {
      padding-left: 30px;
      > * {
        line-height: 25px;
      }
    }
    .media-heading {
      a {
        color: @crt_global_cl;
        &:hover {
          color: @white;
        }
      }
    }
  }
  .AuthorDescription,
  .TzItemPage span.muted {
    color: @white;
  }

}

.tz_portfolio_plus_user {
  .media-body {
    padding-left: 30px;
    > * {
      line-height: 25px;
    }
  }
}

.TzRelated {
  .TzTitle {
    color: @crt_global_cl;
    &:hover {
      color: @white;
    }
  }
}

.fancybox-close {
  left: 50%;
  .translateX(-50%);
  right: auto;
  top: 0;
  &.btn-bottom {
    top: auto;
    bottom: 0;
  }
}

.fancybox-overlay {
  z-index: 9998;
}

.tzPortfolio_Single_tks {
  color: @crt_global_cl;
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-top: 14px;
  text-align: center;
  text-transform: uppercase;
}

.TzCategories {
  .tz_category {
    position: relative;
    .item-title {
      border-bottom: 1px solid #ededed;
      padding: 14px 0;
      a {
        font-size: 14px;
        margin: 0;

        color: @crt_title_cl;
        font-weight: 300;
        &:hover {
          color: @crt_global_cl;
        }
      }
    }
  }
  .btn_plus {
    //position: absolute;
    //right: 0;
    //top: 50%;
    //.translateY(-50%);
    &:hover {
      i {
        color: @crt_global_cl;
      }
    }
  }
  .category-desc {
    padding: 10px 15px;
    border-bottom: 1px solid #ededed;
  }
  .tz_subcategory {
    .tz_category {
      padding-left: 30px;
    }
  }
}

.TzTag,
.TzUser {
  .tz_portfolio_plus_user {
    background: @white;
    padding: 30px;
    margin-bottom: 30px;
  }
  .media {
    .tz-table-cell {
      vertical-align: middle;
    }
    .media-body {
      font-weight: 300;
    }
    span.muted {
      margin-right: 10px;
      color: @crt_title_cl;
      line-height: 22px;
      &:last-of-type::after {
        content: none;
      }
      &:after {
        content: "|";
        font-style: italic;
        margin-left: 3px;
        margin-right: 5px;
        position: relative;
        color: #dedede;
      }
      span, a {
        color: @crt_global_cl;
      }
    }
  }
  .AuthorAvatar {
    img {
      width: 150px;
      max-width: none;
    }
  }
  .TzLeading {
    background: @white;
    .TzTagMedia,
    .TzUserMedia {
      margin: 0;
    }
    .tz_content {
      padding: 48px 30px 45px;
      .TzBlogTitle {
        margin: 0 0 17px 0;
      }
    }
    .TzDescription {
      margin-bottom: 25px;
    }

    .TzReadmore {
      background: @crt_global_cl;
      border-radius: 3px;
      color: @white;
      display: inline-block;
      line-height: 25px;
      padding: 8px 27px;
      text-decoration: none;
    }
  }
  .TzArticleBlogInfo {
    margin: 0 0 22px 0;
    &.muted {
      > * {
        color: #b5b5b5;
        font-style: italic;
        * {
          color: #b5b5b5;
        }
        &:last-child::after {
          content: none;
        }
        &:after {
          content: "|";
          font-style: italic;
          margin-left: 3px;
          margin-right: 5px;
          position: relative;
          color: #dedede;
        }
      }
    }
  }
}

.com_tz_portfolio_plus {
  &.view-date {
    .text-info.date {
      color: @crt_global_cl;
      border-bottom: 1px solid @crt_global_cl;
    }
    .TzItem {
      background: @white;
      .TzBlogMedia {
        margin: 0;
      }
      .tz_content {
        padding: 48px 30px 45px;
      }
      .TzBlogTitle {
        margin: 0 0 17px;
      }
    }
    .TzDescription {
      margin-bottom: 25px;
    }

    .TzReadmore {
      background: @crt_global_cl;
      border-radius: 3px;
      color: @white;
      display: inline-block;
      line-height: 25px;
      padding: 8px 27px;
      text-decoration: none;
    }
    .TzArticleBlogInfo {
      margin: 0 0 22px 0;
      &.muted {
        > * {
          color: #b5b5b5;
          font-style: italic;
          * {
            color: #b5b5b5;
          }
          &:last-child::after {
            content: none;
          }
          &:after {
            content: "|";
            font-style: italic;
            margin-left: 3px;
            margin-right: 5px;
            position: relative;
            color: #dedede;
          }
        }
      }
    }
  }
}

.TzItemPage {
  .button_close_lightbox {
    display: none;
  }
  .TzArticleTitle {
    color: @crt_global_cl;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 48px 0 44px;
    text-align: center;
    text-transform: uppercase;
  }
  .tz-label {
    min-width: 100px;
    color: @color_666;
  }
  .tz-value {
    color: @crt_global_cl;
    * {
      color: @crt_global_cl;
    }
    a:hover {
      color: @crt_title_cl;
    }
  }
  span.muted {
    margin-right: 10px;
    color: @color_666;
    line-height: 22px;
    &:last-of-type::after {
      content: none;
    }
    &:after {
      content: "|";
      font-style: italic;
      margin-left: 3px;
      margin-right: 5px;
      position: relative;
      color: #dedede;
    }
    span, a {
      color: @crt_global_cl;
    }
  }
  .AuthorDescription {
    color: @color_666;
  }
  .AuthorAvatar img {
    max-width: none;
    width: 150px;
  }
}


//offline


.tzCountdowntitle {
  color: #ffffff;
  font-family: "Ubuntu", sans-serif;
  font-size: 48px;
  font-weight: 700;
  margin: 0 0 34px;
  padding: 0;
  text-transform: uppercase;
}

.tzCountdowndes {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 20px;
}

.tzUnder_Construction_top {
  display: block;
  position: relative;
  &:before {
    background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  > * {
    z-index: 1;
  }
  .tzcountdown {
    padding-top: 120px;
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .tzlogo {
    top: 0;
    left: 50%;
    position: absolute;
    padding: 72px 15px 25px;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}

.countdown {
  &:after {
    clear: both;
    display: block;
    content: '';
  }
  .tzcountdownitem {
    float: left;
    span {
      color: #ffffff;
      display: block;
      font-family: "Ubuntu", sans-serif;
      font-size: 65px;
      font-weight: 300;
      line-height: 65px;
      padding: 14px 0 12px;
      text-align: center;
    }
    p {
      color: #ffffff;
      font-size: 16px;
      letter-spacing: 2px;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .tzdivide {
    background: #e5ae49 none repeat scroll 0 0;
    display: block;
    float: left;
    height: 25px;
    margin: 52px 22px;
    width: 1px;
  }
}

.tzbutton {
  margin-top: 75px;
  a.tzbtn {
    background: #e5ae49 none repeat scroll 0 0;
    border: 2px solid #e5ae49;
    border-radius: 25px;
    color: #ffffff;
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 20px 0;
    padding: 10px 27px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out 0s;
    &:hover {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    }
    &.tzbtn-left {
      margin-right: 20px;
    }
    &.tzbtn-right {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      border: 2px solid #ffffff;
      &:hover {
        background: #e5ae49 none repeat scroll 0 0;
        border: 2px solid #e5ae49;
      }
    }
  }
}

.tzUnder_Construction_left,
.tzUnder_Construction_right {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}

.tzUnder_Construction_right {
  img {
    width: 100%;
  }
}

.tzUnder_Construction_About_title {
  color: #252525;
  font-size: 25px;
  text-transform: uppercase;
  em {
    color: #e5ae49;
    font-style: normal;
  }
}

.tzUnder_Construction_Notify_title {
  color: #db4437;
  font-size: 20px;
  letter-spacing: 0.5px;
  margin-bottom: 22px;
  text-transform: uppercase;
}

#mc-embedded-subscribe-form_footer {
  width: 50%;
  margin-top: 30px;
  #mce-EMAIL_footer {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #dcdcdc;
    font-size: 14px;
    line-height: normal;
    padding: 12px 0;
    width: 100%;
  }
}

.block_padding {
  padding: 0 105px;
}