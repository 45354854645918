/* Title Module */
.style1 {
  .module-title {
    font-size: 35px;
    color: @crt_title_cl;
    font-weight: 700;
    line-height: 45px;
    margin-bottom: 31px;
    text-transform: uppercase;
    display: block;
    letter-spacing: 0.5px;
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 70px;
      height: 4px;
      position: absolute;
      bottom: -15px;
      left: 50%;
      margin-left: -35px;
      background: @crt_global_cl;
    }
    em {
      color: @crt_global_cl;
      font-style: normal;
    }
  }
}

.style2 {
  .module-title {
    margin-bottom: 30px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 27px;
    color: @white;
    text-transform: uppercase;
    margin-top: 0;
    font-size: 25px;
  }
}

.tz_desc_for_title_mod {
  padding: 0 20%;
  color: @color_666;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 300;
}

/* Carousle Category Porfolio */

.category-menu-portfolio {
  &.style-grid {
    > .row {
      margin: 0;
    }
    .item-cat {
      padding: 0;
    }
  }
  .tz_link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
  }
  .item-cat {
    position: relative;
    cursor: pointer;

    .content-cat {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      &::after {
        background: @white;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        .transition(all 0.3s ease-in-out 0s);
      }
      .item {
        position: absolute;
        width: 100%;
        z-index: 1;
        top: 50%;
        .translateY(-50%);
        text-align: center;
        span.tz-icon-category {
          color: @crt_title_cl;
          font-size: 30px;
          margin-bottom: 13px;
        }
        .title {
          font-weight: 300;
        }
      }
    }
    &:hover {
      .content-cat:after {
        background: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

.category-full-width {
  .tz_link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
  }
  .item-cat {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    .level00 {
      position: absolute;
      top: 0;
      left: 0;
      right: 1px;
      bottom: 0;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      .transition(all 0.3s ease-in-out 0s);
      .content-cat {
        top: 50%;
        .translateY(-50%);
        position: absolute;
        display: block;
        width: 100%;
      }
    }
    .tz_title, .tz-icon-category {
      color: @white;
    }
    .tz-icon-category {
      font-size: 48px;
      margin-bottom: 3px;
      position: relative;
      z-index: 99;
    }
    .tz_title {
      font-size: 18px;
      margin: 0;
      padding: 0;
      position: relative;
      text-transform: uppercase;
      .transition(all 0.3s ease-in-out 0s);
      z-index: 99;
    }
    &:hover {
      .level00 {
        background: rgba(0, 0, 0, 0.8);
      }
      .tz_title {
        color: @crt_global_cl;
      }
    }
  }
}

/* End Carousle Category Porfolio */

/* Mod Portfolio Articles */

.k2_services {
  margin: 0 -15px;
  width: auto !important;
  .owl-item {
    padding: 0 15px;
  }
  .tz-icon-article {
    font-size: 85px;
    color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .tz_service_content {
    position: relative;
  }
  .tz_service_title {
    margin-bottom: 15px;
    margin-top: 0;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    color: @crt_title_cl;
    letter-spacing: 1px;
  }
  .tz_service_desc {
    margin-bottom: 3px;
  }
  .tz_service_image {
    margin-bottom: 24px;
  }
  .tz-service-readmore {
    font-weight: 300;
    font-size: 13px;
    color: @crt_global_cl;
    letter-spacing: 0.5px;
    text-decoration: none;
  }
}

.tz_portfolio_room,
.k2_rooms {
  .tz_portfolio_plus_image {
    z-index: auto;
  }
  .TzPortfolioReadmore {
    color: @white;
    font-size: 11px;
  }
  &.owl-carousel .owl-stage-outer {
    overflow: visible;
  }
  .tz_room_image {
    position: relative;
    img {
      position: relative;
      max-width: none;
    }
    &:after {
      background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
      content: "";
      display: block;
      bottom: 0;
      right: 0;
      left: 0;
      position: absolute;
      top: 0;
    }
  }
  .owl-item {
    -webkit-transition: all 350ms ease 0s;
    -moz-transition: all 350ms ease 0s;
    -ms-transition: all 350ms ease 0s;
    -o-transition: all 350ms ease 0s;
    transition: all 350ms ease 0s;
    overflow: hidden;
    &.center {
      -webkit-transform: scale(1.25);
      -moz-transform: scale(1.25);
      -ms-transform: scale(1.25);
      -o-transform: scale(1.25);
      transform: scale(1.25);
      z-index: 99999;
      .tz_room_content {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        visibility: visible;
        opacity: 1;
      }
      .tz_room_image {
        &:after {
          background: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
  .tz_room_content {
    position: absolute;
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    color: @white;
    padding: 0 15px;
    background: rgba(255, 255, 255, 0.1);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  .tz_room_tag {
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: center;
    a {
      color: @white;
    }
  }
  .tz_room_title {
    margin-bottom: 14px;
    padding: 0 0 13px;

    position: relative;
    a {
      color: @crt_global_cl;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.5px;
      line-height: 24px;
      text-decoration: none;
      text-transform: uppercase;
    }
    &:after {
      background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
      bottom: 0;
      content: "";
      display: block;
      height: 1px;
      left: 50%;
      margin-left: -20px;
      position: absolute;
      width: 40px;
    }
  }
  .owl-controls .owl-nav > div.owl-prev {
    left: 0;
  }
  .owl-controls .owl-nav > div.owl-next {
    right: 0;
  }
}

/* Testimonial */
.tz-testimonial {
  .item-testimonial {
    padding: 0 170px;
    text-align: center;
  }
  .tz_testimonial_desc {
    color: @white;
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    line-height: 32px;
    margin-bottom: 65px;
  }
  .tz_testimonial_info {
    padding-bottom: 68px;
    position: relative;
    max-width: 330px;
    margin: auto;
    &:after {
      background: #2c2c2c none repeat scroll 0 0;
      bottom: 1px;
      content: " ";
      height: 1px;
      left: 165px;
      opacity: 0;
      position: absolute;
      right: 165px;
      transition: all 0.3s ease 0.5s;
    }
  }
  .tz_testimonial_name {
    color: @crt_global_cl;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 2px;
    padding-bottom: 5px;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }
  .tz_testimonial_job {
    color: @white;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  .tz_testimonial_avatar {
    border: 1px solid @crt_global_cl;
    border-radius: 50%;
    display: inline-block;
    height: 100px;
    margin: 0 0 30px;
    padding: 3px;
    width: 100px;
  }
  .owl-item.active {
    .tz_testimonial_info {
      &:after {
        left: 0;
        opacity: 1;
        right: 0;
        transition: all 0.3s ease 0.5s;
      }
    }
  }
  .owl-controls .owl-nav > div.owl-prev {
    left: 50%;
    margin-left: -150px;
  }
  .owl-controls .owl-nav > div.owl-next {
    margin-right: -150px;
    right: 50%;
  }
  .owl-controls .owl-nav > div {
    background: transparent !important;
    color: #686868;
    font-size: 30px;
  }
}

.tz-testimonials {
  padding: 0 175px;
  bottom: -80px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 9999;
  > div {
    background: @white;
    padding: 15px;
  }
}

.tz-testimonial-style2 {
  background: @white;
  border: 2px solid @crt_global_cl;
  .owl-item {
    background: @white;
  }
  .tz_testimonial_avatar {
    top: 15px;
    position: relative;
    .tz_avatar {
      width: auto;
      margin: auto;
    }
  }
  .tz_testimonial_desc {
    top: -15px;
    position: relative;
    padding: 0 70px;
    color: @crt_title_cl;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 23px;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-style: italic;
  }
  .owl-controls .owl-dots {
    padding: 0;
    top: -18px;
    position: relative;
  }
}

.tz-testimonial-style3 {
  .item-testimonial {
    padding: 0 170px;
  }
  .tz_testimonial_avatar {
    background: @white;
    border-radius: 50%;
    display: inline-block;
    height: 110px;
    margin: 0 0 40px;
    padding: 3px;
    width: 110px;
  }
  .tz_testimonial_desc {
    color: @white;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 35px;
    font-weight: 300;
  }
  .tz_testimonial_name {
    color: @crt_global_cl;
    display: block;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
  }
  .tz_testimonial_job {
    color: @white;
    display: block;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    text-align: center;
  }
  .owl-controls {
    margin: 20px 0 0;
    .owl-dots {
      padding: 0;
      .owl-dot {
        padding: 0 7px;
        &.active {
          span {
            background: @black;
          }
        }
        span {
          background: @white;
          &:after {
            border-color: @white;
          }
        }
      }
    }
  }
}

.tz-testimonial-style-4 {
  .tz_testimonial_desc {
    color: #ececec;
    font-size: 17px;
    font-style: italic;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 56px;
    letter-spacing: 0.5px;
  }
  .tz_testimonial_info {
    display: inline-block;
    i {
      background: #e5ae49 none repeat scroll 0 0;
      border-radius: 50%;
      color: #ffffff;
      display: block;
      font-size: 25px;
      font-variant: normal;
      font-weight: normal;
      height: 40px;
      left: 0;
      line-height: 1;
      padding: 7px 0;
      text-align: center;
      text-transform: none;
      top: 0;
      width: 40px;
    }
    .tz_testimonial_name {
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 2px;
      text-align: left;
      text-transform: uppercase;
    }
    .tz_testimonial_job {
      color: #ffffff;
      font-size: 14px;
      font-style: italic;
      font-weight: 300;
      text-align: left;
    }
    .tz_testimonial_job,
    .tz_testimonial_name {
      margin: 0 0 0 10px;
    }
  }
}

/* End Testimonial */

.tz_align_center {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.line_left {
  &:before {
    background: rgba(255, 255, 255, 0.2) none repeat scroll 0 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  &:after {
    background: rgba(255, 255, 255, 0.2) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 1px;
  }
}

.line_right {
  &:before {
    background: rgba(255, 255, 255, 0.2) none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  &:after {
    background: rgba(255, 255, 255, 0.2) none repeat scroll 0 0;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
  }
}

.line_left,
.line_right {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Count */
.circular_skills {
  &.style2 {
    .stat-count {
      margin-bottom: 13px;
      position: relative;
      &:after {
        background: rgba(255, 255, 255, 0.2);
        bottom: -1px;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        margin-left: -15px;
        position: absolute;
        width: 30px;
      }
    }
    .tz_count_title {
      color: @white;
      font-weight: 400;
      letter-spacing: 2px;
    }
  }
  &.style3 {
    .item-count {
      margin-bottom: 30px;
      &:hover {
        .tz_icon {
          i {
            background: @crt_global_cl;
            border-color: @crt_global_cl;
            color: @white;
          }
        }
      }
    }
    .tz_info,
    .tz_icon {
      display: table-cell;
    }
    .tz_icon {
      width: 88px;
      position: relative;
      &:before {
        content: '';
        border-right: 1px solid #e3e3e3;
        position: absolute;
        bottom: -85px;
        height: 85px;
      }
      i {
        border: 1px solid #e3e3e3;
        width: 100%;
        height: 88px;
        display: block;
        font-size: 35px;
        padding: 25px 0;
        .transition(all 0.3s ease 0s);
      }
    }
    .tz_info {
      padding-left: 22px;
      vertical-align: top;
    }
    .stat-count {
      color: @crt_title_cl;
      font-size: 40px;
      font-weight: 500;
      line-height: 40px;
      margin-top: 19px;
    }
    .tz_count_title {
      color: @color_666;
      display: block;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 24px;
    }
    .item-count {
      position: relative;
      min-height: 175px;
      padding-left: 55px;
      padding-right: 0;
      &:after {
        content: '';
        border-bottom: 1px solid #e3e3e3;
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 1px;
        left: 100px;
      }
    }
  }
  .item-count {
    padding: 0 0 30px;
  }
  .icon-content {
    border: medium none;
    margin: 0 0 13px;
    padding: 0;
    i {
      color: @white;
      font-size: 46px;
    }
  }
  .stat-count {
    margin-bottom: 5px;
    color: @crt_global_cl;
    font-size: 50px;
    font-weight: 700;
    margin-top: 0;
    line-height: 1.42857;
  }
  .tz_count_title {
    color: @white;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 25px;
  }
}

/* End Count */

/*Skill*/
.tz_skills {
  .absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    .translate(-50%, -50%);
    .percent {
      position: relative;
      color: @crt_title_cl;
      display: inline-block;
      font-size: 33px;
      font-weight: 300;
      padding-right: 15px;
      z-index: 2;
      &:after {
        color: @crt_title_cl;
        content: "%";
        font-size: 20px;
        position: absolute;
        right: 0;
        top: 5px;
      }
    }
  }
  .title {
    font-weight: 700;
    margin-bottom: 18px;
    padding: 0 20px;
  }
  .chart {
    position: relative;
    margin: 0 auto 30px;
  }
  .description {
    padding: 0 20px;
  }
  &.style2 {
    .chart {
      &:before {
        background: #f3f3f3;
        border-radius: 50%;
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        margin: 30px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

/* Recent */
.k2ItemsBlock {
  &.recent-right {
    ul {
      margin: 0;
      li {
        border-bottom: 1px solid #ededed;
        margin: 0 0 20px 0;
        padding: 0 0 20px 0;
        &:last-child {
          border: none;
          padding: 0;
          margin: 0;
        }
      }
    }
    .recent_info {
      .moduleItemTitle {
        color: @crt_title_cl;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 4px;
        text-decoration: none;
      }
      .moduleItemDateCreated {
        color: @crt_global_cl;
      }
    }
    .lastItem {
      margin: 0 !important;
    }
  }
  &.text-widget {
    ul {
      padding: 0 !important;
      li {
        margin: 0 !important;
      }
    }
    .recent_info {
      padding: 0;
      .moduleItemIntrotext {
        padding: 0;
        font-size: 14px;
        font-weight: 300;
        line-height: 25px;
      }
    }
  }
  .moduleItemImage_recent {
    display: block;
    max-height: 80px;
    max-width: 80px;
  }
  .recent_info {
    padding-left: 15px;
    .moduleItemTitle {
      color: @white;
      display: block;
      font-weight: 400;
      letter-spacing: 1px;
      margin-bottom: 15px;
    }
    .moduleItemComments,
    .moduleAttachments,
    .moduleItemCategory,
    .moduleItemDateCreated {
      color: #9b9b9b;
      letter-spacing: 1px;
      display: inline-block;
      border: none;
      &:after {
        content: '/';
      }
    }
    *:last-child:after {
      content: '';
    }

  }
}

/* End Recent */

/* Feature */
.tz_features {
  &.our_services {
    .tz_feature {
      border-color: rgba(0, 0, 0, 0.2) !important;
      margin-bottom: 30px;
    }
    .tz_feature_button,
    .tz_feature_desc,
    .tz_feature_job,
    .tz_feature_title {
      color: @crt_title_cl;
    }
    .tz_feature_desc {
      padding: 0;
      margin: 40px 0 20px;
      font-weight: 300;
    }
    .line_right::before,
    .line_right::after,
    .line_left::before,
    .line_left::after {
      background: @crt_title_cl;
    }
  }
  .tz-item {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .tz_feature {
    background: rgba(255, 255, 255, 0.1);
    padding: 54px 60px 59px;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: @white;
    position: relative;
    cursor: pointer;
    &:hover {
      .line_right {
        &:after {
          .scaleY(1);
        }
        &:before {
          .scaleX(1);
        }
      }
      .line_left {
        &:after {
          .scaleY(1);
        }
        &:before {
          .scaleX(1);
        }
      }
    }
  }
  .tz_feature_title {
    color: @crt_global_cl;
    font-size: 24px;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 1px;
    margin: 0 0 3px;
    text-transform: uppercase;
    z-index: 1;
    position: relative;
  }
  .tz_feature_job {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 10px;
    color: @white;
    z-index: 1;
    position: relative;
  }
  .tz_feature_desc {
    padding: 50px 0 5px;
    line-height: 1.8em;
    z-index: 1;
    position: relative;
    input {
      background: transparent;
      border-color: @white;
      border-width: 0 0 1px;
      border-style: solid;
      line-height: 24px;
      margin-top: 15px;
      padding: 5px 20px;
      color: @crt_global_cl;
    }
  }
  .tz_feature_button {
    text-transform: uppercase;
    font-size: 13px;
    color: @white;
    letter-spacing: 2px;
    font-weight: 700;
    margin-top: 14px;
    display: block;
    z-index: 1;
    position: relative;
    .transition(all 0.3s ease 0s);
    &:hover {
      color: @crt_global_cl;
    }
    i {
      padding-left: 5px;
    }
  }
  .line_right {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    &:after {
      .scaleY(0);
      transform-origin: center bottom 0;
    }
    &:before {
      .scaleX(0);
      transform-origin: right center 0;
    }
    &:after, &:before {
      background: @white;
      .transition(all 0.4s ease 0s);
    }

  }
  .line_left {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    &:after {
      .scaleY(0);
      transform-origin: center top 0;
    }
    &:before {
      .scaleX(0);
      transform-origin: left center 0;
    }
    &:after, &:before {
      background: @white;
      .transition(all 0.4s ease 0s);
    }
  }
}

.tz_features_2 {
  .tz_feature {
    padding-bottom: 60px;
    &:first-child {
      padding-top: 30px;
    }
    &.text-right {
      .tz_value {
        left: auto;
        right: -2px;
      }
      .tz_content {
        padding-right: 25px;
        padding-left: 0;
      }
    }
    &:hover {
      .tz_icon {
        background: @white;
        i {
          color: @crt_global_cl;
        }
      }
    }
    .tz_feature_desc {
      font-weight: 300;
      line-height: 25px;
      letter-spacing: 0.5px;
    }
    .tz_feature_title {
      font-weight: 700;
      padding: 3px 0;
      margin: 0 0 13px 0;
    }
    .tz_content {
      padding-left: 25px;
    }
    .tz_icon {
      width: 75px;
      height: 75px;
      background: #e5ae49;
      border: 2px solid #e5ae49;
      border-radius: 50%;
      text-align: center;
      padding: 25px 0;
      position: relative;
      .transition(all 0.3s ease-in-out 0s);
      i {
        font-size: 25px;
        color: #ffffff;
        .transition(all 0.3s ease-in-out 0s);
      }
    }
    .tz_value {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 25px;
      height: 25px;
      border: 2px solid #e5ae49;
      background: #FFFFFF;
      border-radius: 50%;
      font-weight: 400;
      font-size: 14px;
      color: #e5ae49;
      text-align: center;
      padding: 1px 0;
    }
  }
}

/* End Feature */

/* Blog */
.tz_grid {
  float: left;
  position: relative;
  padding: 15px;
  &.col-2 {
    width: 50%;
  }
  &.row-2 {
    height: 600px;
  }
  &.row-1 {
    height: 300px;
  }
  &.col-1 {
    width: 25%;
  }
  &.col-2.row-1,
  &.col-1.row-1 {
    .tz_desc {
      display: none;
    }
  }
}

.k2_blog {
  margin: -15px;
  width: auto !important;
}

.tz_blog {
  display: block;
  &:after {
    display: block;
    content: '';
    clear: both;
  }
  .tz_image {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: block;
    img {
      position: relative;
      width: auto;
      max-width: none;
    }
    &:after {
      content: '';
      background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.75) 100%) repeat scroll 0 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .tz_content {
    position: absolute;
    top: auto;
    left: 45px;
    right: 45px;
    bottom: 45px;
    overflow: hidden;
    span:last-of-type:after {
      content: '';
    }
  }
  .tz_title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    a {
      color: @crt_global_cl;
    }
  }
  .tz_desc {
    margin-bottom: 30px;
    p {
      font-weight: 400;
      line-height: 22px;
    }
  }
  .tz_author,
  .tz_attachments,
  .tz_comments,
  .tz_hit,
  .tz_category,
  .tz_tag,
  .tz_date,
  .tz_desc {
    color: @white;
    font-size: 13px;
    * {
      color: @white;
    }
    i {
      padding: 0 10px 0 0;
    }
  }
  .col-2 {
    .tz_author,
    .tz_attachments,
    .tz_comments,
    .tz_hit,
    .tz_category,
    .tz_tag,
    .tz_date {
      &:after {
        content: '/';
        display: inline-block;
        padding: 0 18px;
        font-size: 10px;
        line-height: 1.5em;;
      }
    }
    &.row-1 {
      .tz_title {
        margin: 0 0 25px;
      }
    }
  }
  .col-1 {
    .tz_author,
    .tz_attachments,
    .tz_comments,
    .tz_hit,
    .tz_category,
    .tz_tag,
    .tz_date {
      display: block;
      margin-bottom: 13px;
    }
    .tz_title {
      margin: 0 0 25px;
    }
    .tz_content {
      span:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

/* End Blog */

/* Partner */
.tz-partner {
  .item-partner {
    padding-bottom: 40px;
  }
  .tz_partner_avatar {
    text-align: center;
    img {
      margin: auto;
    }
  }
}

/* Contact */
.tz-table-cell,
.contact-icon,
.contact-content {
  display: table-cell;
  vertical-align: top;
}

.contact-icon {
  color: #868686;
  font-size: 16px;
  padding-right: 20px;
}

.contact-miscinfo {
  margin-bottom: 32px;
  .contact-misc {
    .contact-content {
      color: #9b9b9b;
      line-height: 25px;
      p {
        margin: 0;
      }
    }
  }
}

.tz-contact-address {
  .contact-website,
  .contact-fax,
  .contact-phone,
  .contact-email,
  .contact-address {
    margin: 15px 0 0;
    color: #868686;
    font-weight: 300;
    line-height: 23px;
  }
}

#contact-form {
  color: #9b9b9b;
  input[type='email'],
  input[type='password'],
  input[type='text'], textarea {
    background: transparent;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: @white;
    width: 100%;

  }
  input[type='email'],
  input[type='password'],
  input[type='text'] {
    height: 45px;
    line-height: 45px;
    margin-bottom: 23px;
    padding: 0 0 8px;
  }
  input[type='checkbox'] {
    position: absolute;
    left: 0;
  }
  .emailCopy {
    margin-top: 15px;
    position: relative;
    label {
      padding-left: 25px;
      font-size: 12px;
    }
  }
  #message-sent,
  #message-sent-false {
    display: none;
  }
  .tz_contact_submit {
    border-color: @crt_global_cl;
    color: @crt_global_cl;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 22px 7px 0;
    width: auto;
    border-radius: 0;
    border-style: solid;
    border-width: 0 0 1px;
    margin-bottom: 13px;
    background: transparent;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

/* End Contact */

/* Control Navigation Carousel*/
.owl-controls {
  .owl-dots {
    margin: auto;
    padding: 0 0 18px;
    text-align: center;
    .owl-dot {
      display: inline-block;
      padding: 0 5px;
      &.active {
        span {
          background: @white;
          &:after {
            opacity: 1;
            .scale(1);
            visibility: visible;
          }
        }
      }
      span {
        background: #dfdfdf;
        height: 10px;
        margin: 0;
        opacity: 1;
        position: relative;
        .transition(all 0.3s ease-in-out 0s);
        width: 10px;
        display: block;
        border-radius: 20px;
        &:after {
          border: 1px solid @crt_global_cl;
          border-radius: 50%;
          content: "";
          display: block;
          height: 12px;
          left: -1px;
          opacity: 0;
          position: absolute;
          top: -1px;
          .scale(1.5);
          .transition(all 0.3s ease-in-out 0s);
          visibility: hidden;
          width: 12px;
        }
      }
    }
  }
  .owl-nav {
    > div {
      position: absolute;
      top: 50%;
      margin-top: -25px;
      width: 50px;
      height: 50px;
      background: @white;
      color: @crt_title_cl;
      z-index: 99999;
      .transition(all 0.3s ease-in-out 0s);
      font-size: 25px;
      text-align: center;
      i {
        line-height: 50px;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.75);
      }
      &.owl-next {
        right: 15px;
      }
      &.owl-prev {
        left: 15px;
      }
    }
  }
}

/* Search */
.k2SearchBlock {
  position: relative;
  .tz_icon_search {
    padding: 45px 0 40px 8px;
    display: inline-block;
    &.active {
      i {
        color: @crt_global_cl;
        &:before {
          content: '\4d';
        }
      }
    }
    i {
      color: @white;
      font-size: 17px;
    }
  }

  .k2SearchBlockForm {
    border-top: 3px solid @crt_title_cl;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 120%;
    left: auto;
    right: 0;
    z-index: 1;
    .transition(all 0.3s ease-in-out 0s);
    .inputbox {
      padding: 15px 20px;
      border: none;
      color: #bebebe;
      font-style: italic;
      min-width: 270px;
    }
    .button {
      position: absolute;
      right: 0;
      //text-indent: -9999em;
      background: transparent;
      border: none;
      .box-shadow(none);
      top: 0;
      bottom: 0;
      padding: 0 20px;
      color: @crt_global_cl;
    }
    &.active {
      opacity: 1;
      visibility: visible;
      top: 100%;
    }
  }
}

.k2SearchBlock-default {
  position: relative;
  input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #dedede;
    color: #111111;
    font-style: italic;
    font-weight: 300;
    line-height: 24px;
    padding: 9px 45px 10px 15px;
    width: 100%;
    .box-shadow(none);

  }

  .input-box::-webkit-input-placeholder {
    color: #f00;
  }
  .input-box::-moz-placeholder {
    color: #f00;
  }
  /* firefox 19+ */
  .input-box:-ms-input-placeholder {
    color: #f00;
  }
  /* ie */
  .input-box:-moz-placeholder {
    color: #f00;
  }

  .button {
    background: @crt_global_cl;
    color: @white;
    height: 45px;
    line-height: 45px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 45px;
  }

}

/* Cart */

.hikashop_cart_module {
  position: relative;
  tbody {
    td {
      padding: 15px 15px 15px 0;
      border-bottom: 1px solid #ededed;
      vertical-align: top;
      &.hikashop_cart_module_product_price_value,
      &.hikashop_cart_module_product_delete_value {
        padding-right: 0;
      }
      &.hikashop_cart_module_product_image {
        padding-top: 0;
      }
      &.hikashop_cart_info {
        width: 100%;
      }
    }
    tr:last-child {
      td {
        border: none;
        padding-bottom: 0;
      }
    }
  }
  .hikashop_cart_module_product_name_value {
    margin-bottom: 2px;
  }
  .hikashop_cart_module_product_total_title {
    .hikashop_product_price_full {
      display: inline-block;
    }
  }
  .btn {
    border-radius: 2px;
    padding: 8px 15px;
    font-size: 14px;
    margin-right: 2px;
  }
  .hikashop_product_price_full {
    padding-left: 10px;
    color: @crt_global_cl;
  }
  .hikashop_cart_value {
    &.hikashop_cart_module_product_price_value {
      display: inline-block;
      .hikashop_product_price_full {
        display: inline-block;
        > * {
          display: block;
        }
      }
    }
    &.hikashop_cart_module_product_quantity_value {
      display: inline-block;
      input {
        width: 20px;
        text-align: center;
        border: none;
      }
    }
  }
  .tz_icon_cart {
    display: block;
    padding: 45px 0 40px 22px;
    &.active {
      i {
        color: @crt_global_cl;
        &:before {
          content: '\4d';
        }
      }
    }
    i {
      color: @white;
      font-size: 17px;
    }
  }
  .hikashop_cart {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 120%;
    min-width: 430px;
    left: auto;
    right: 0;
    .transition(all 0.3s ease-in-out 0s);
    background: @white;
    .box-shadow(0 0 5px 2px rgba(0, 0, 0, 0.1));
    border-top: 3px solid @crt_title_cl;
    padding: 30px;
    z-index: 1;
    &.active {
      top: 100%;
      opacity: 1;
      visibility: visible;
    }
  }
}

/* Language */
.mod-languages {
  position: relative;
  .chzn-container-single {
    width: auto !important;
    .chzn-single {
      background: transparent;
      border: none;
      border-radius: 0;
      .box-shadow(none);
    }
    .chzn-drop {
      border-width: 3px 1px 1px 1px;
      border-color: @crt_title_cl #dcdcdc #dcdcdc #dcdcdc;
      border-style: solid;
      border-radius: 0;
      margin: 0;
    }
  }
  .lang-block {
    position: absolute;
    top: 120%;
    border-width: 3px 1px 1px 1px;
    border-color: @crt_title_cl #dcdcdc #dcdcdc #dcdcdc;
    border-style: solid;
    border-radius: 0;
    visibility: hidden;
    opacity: 0;
    .transition(all 0.3s ease-in-out 0s);
    width: 100%;
    max-width: 250px;
    z-index: 99999;
    background: @white;
    &.active {
      visibility: visible;
      opacity: 1;
      top: 100%;
    }
    li {
      border-bottom: 1px solid #dcdcdc;
      margin: 0;
      padding: 0;
      &:last-child {
        border: none;
      }
      img {
        margin-right: 15px;
      }
      a {
        padding: 15px 30px;
        color: #333333;
        font-weight: 300;
        display: block;
        &:hover {
          background: #ededed;
          color: @crt_title_cl;
        }
      }
    }
  }
  .tz_language {
    padding: 14px 20px 14px 0;
    font-size: 13px;
    font-weight: 300;
    color: #333333;
    display: block;
    position: relative;
    &:after {
      content: '\f107';
      font-family: FontAwesome;
      top: 15px;
      right: 0;
      position: absolute;
    }
    i {
      font-size: 14px;
      padding-right: 7px;
    }
  }
}

/* Service Grid */
.tz_content_our_services {
  p {
    margin-bottom: 23px;
  }
}

.tz_services_grid {
  .tz_link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
  }
  > .row {
    margin: 0;
    div[class*="col-"] {
      padding: 0;
    }
  }
  &.style-grid-2 {
    .tz_image {
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  &.style2 {
    border: none;
    > .row {
      margin: 0 -15px;
      div[class*="col-"] {
        padding: 0 15px;
      }
    }
    .tz_content {
      position: relative;
      padding: 30px 0 35px;
    }
    .tz_service_grid {
      border: none;

      .tz_image:after {
        content: none;
      }

      .tz_icon {
        color: @crt_global_cl !important;
        font-size: 32px;
      }
      .tz_title {
        color: @crt_title_cl !important;
        font-weight: 400;
        letter-spacing: 2px;
        margin: 0 0 12px 0;
        padding: 3px 0 18px;
      }
      .tz_desc {
        color: @color_666 !important;
      }
    }
  }
  .tz-item {
    cursor: pointer;
  }
}

.tz_service_grid {
  position: relative;
  border: 1px solid #e3e3e3;
  margin: -1px 0 0 -1px;
  .transition(all 0.3s ease-in-out 0s);
  overflow: hidden;
  &:hover {
    .tz_image:after {
      background: rgba(0, 0, 0, 0.8);
    }
    .tz_content {
      .tz_desc,
      .tz_title,
      .tz_icon {
        color: @white;
      }
    }
  }
  .tz_content {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 35px;
    .tz_icon, .tz_info {
      display: table-cell;
      vertical-align: top;
    }
    .tz_icon {
      width: 65px;
      color: @crt_title_cl;
      font-size: 35px;
    }
    .tz_job {
      color: @crt_global_cl;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1px;
      margin: 0 0 9px;
      padding: 0;
      text-transform: uppercase;
    }
    .tz_title {
      color: @crt_title_cl;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1px;
      margin: 0 0 18px;
      padding: 0 0 23px;
      position: relative;
      text-transform: uppercase;
      &:after {
        background: @crt_global_cl;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        width: 30px;
      }
    }
    .tz_desc {
      color: @color_666;
      font-weight: 300;
      letter-spacing: 0.5px;
      line-height: 25px;
    }
  }
  .tz_image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &:after {
      background: @white;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .transition(all 0.3s ease-in-out 0s);
      position: absolute;
    }
  }
}

/* Masonry */
.tz_masonry {
  .tz_masonry_item {
    .transition(all 0.3s ease-in-out 0s);

  }
  .tz_info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &:before {
      content: '';
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .transition(all 0.3s ease-in-out 0s);
    }
    .bottom, .top, .middle {
      z-index: 1;
      position: absolute;
      right: 30px;

      > * {
        text-align: right;
        color: @white;
        font-weight: 300;
      }
    }
    .top {
      top: 32px;
      left: 30px;
      > * {
        margin-bottom: 6px;
      }
      a {
        color: @white;
        &:hover {
          color: @crt_global_cl;
        }
      }
    }
    .middle {
      top: 50%;
      left: 30px;
    }
    .bottom {
      bottom: 33px;
    }
    .tz_title {
      color: @crt_global_cl !important;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin: 0 0 6px;
      display: block;
    }
    .tz_tags {
      letter-spacing: 2px;
    }
    .tz_comment {
      display: block;
    }
    .tz_readmore {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
      overflow: hidden;
      text-transform: uppercase;
      .transition(all 0.5s ease-in-out 0s);
      display: block;
      position: relative;
      &:hover {
        color: @crt_global_cl;
        &:after {
          left: 100%;
        }
      }
      &:after {
        background: @crt_global_cl;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: -100%;
        position: absolute;
        .transition(all 0.5s ease-in-out 0s);
        width: 100%;
      }
    }
  }
  .tz_image {
    width: 100%;
    height: 100%;
    a {
      display: block;
      max-height: none;
      max-width: none;
    }
    img {
      position: relative;
      max-width: none;
      .transition(all 0.5s ease-in-out 0s);
    }
  }
  .tz_inner {
    overflow: hidden;
    position: relative;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: @white;
    cursor: pointer;
    &:hover {
      .tz_info:before {
        background: rgba(0, 0, 0, 0.8);
      }
      .tz_image img {
        .scale(1.1);
      }
    }
  }
}

/* Tab */
.nav-tabs {
  li {
    a {
      display: inline-block !important;
    }
  }
}

.tab-style {
  .nav-tabs {
    text-align: center;
    margin-bottom: 25px;
    border: none;
    li {
      float: none !important;
      display: inline-block !important;
      padding: 0;
      margin: 0;
      a {
        border: none !important;
        text-transform: uppercase;
        background: transparent !important;
        padding: 20px 40px;
        font-size: 17px;
        font-weight: 500;
        letter-spacing: 0.5px;
        &:after {
          content: '\f006';
          font-family: FontAwesome;
          font-size: 14px;
          color: #d9d9d9;
          right: -8px;
          position: absolute;
          top: 0;
          padding: 20px 0;
          line-height: 24px;
        }
        &:hover, &:focus {
          color: @crt_global_cl;
        }
      }
      &.active a {
        color: @crt_global_cl;
      }
      &:last-child a:after {
        content: '';
      }
    }
  }
}

.tab-style-2 {
  .tab-content {
    .tab-pane {
      .transition(opacity 0.3s linear 0s);
    }
  }
  ul {
    margin: 0 0 166px;
    border: none;
    li {
      margin: 0 30px 30px 0;
      padding: 0;
      border: 1px solid @white;
      &:last-child {
        margin-right: 0;
      }
      &.active,
      &.open {
        a {
          background: @white !important;
          &:after {
            background: @crt_title_cl;
          }
          &:before {
            background: @crt_title_cl;
          }
          .tab_span {
            color: @crt_title_cl;
          }
        }
      }
      &.open {
        a {
          &:after,
          &:before {
            -webkit-transform: rotateZ(90deg);
            -moz-transform: rotateZ(90deg);
            -ms-transform: rotateZ(90deg);
            -o-transform: rotateZ(90deg);
            transform: rotateZ(90deg);
          }
        }
      }
      a {
        display: block !important;
        width: 169px;
        height: 169px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
        position: relative;
        margin: 0;
        padding: 0;
        cursor: pointer;
        background: transparent !important;
        .box-shadow(none);
        border: none !important;
        .transition(all 0.3s ease-in-out 0s);
        &:after {
          content: '';
          display: block;
          width: 1px;
          height: 30px;
          background: @white;
          position: absolute;
          top: 19px;
          left: 33px;
          -webkit-transform: rotateZ(0deg);
          -moz-transform: rotateZ(0deg);
          -ms-transform: rotateZ(0deg);
          -o-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
          .transition(all 0.3s ease-in-out 0s);
        }
        &:before {
          content: '';
          display: block;
          width: 30px;
          height: 1px;
          background: @white;
          position: absolute;
          top: 33px;
          left: 19px;
          -webkit-transform: rotateZ(0deg);
          -moz-transform: rotateZ(0deg);
          -ms-transform: rotateZ(0deg);
          -o-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
          .transition(all 0.3s ease-in-out 0s);
        }
      }
      .tab_span {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 13px 20px;
        .transition(all 0.3s ease-in-out 0s);
        letter-spacing: 0.5px;
        color: @white;
        text-transform: uppercase;
      }
    }
  }
}

/* Start Specific Item*/
.tz_specific_item {
  .specific_item {
    padding: 0 0 5px 0;
    .moduleItemIntrotext {
      color: #a2a4a8;
      font-size: 13px;
      line-height: 25px;
    }
    .moduleItemReadMore {
      color: @crt_global_cl;
    }
  }
}

/* End Specific Item*/

/* Flickr */
.flickr {
  margin: -6px;
  li {
    display: inline-block;
    padding: 6px;
    a {
      display: block;
      position: relative;
      text-align: center;
      height: 75px;
      width: 75px;
      &:before {
        content: '';
        background: rgba(251, 192, 45, 0.75);
        position: absolute;
        top: 0;
        left: 0;
        right: 100%;
        bottom: 0;
        .transition(all 0.3s ease-in-out 0s);
      }
      i {
        position: absolute;
        width: 100%;
        line-height: 75px;
        .scale(0);
        visibility: hidden;
        .transition(all 0.3s ease-in-out 0s);
        color: @white;
      }
      &:hover {
        &:before {
          right: 0;
        }
        i {
          .scale(1);
          visibility: visible;
        }
      }
    }
  }
}

/* End Flickr */

/* Menu Quick Link */
.nav.menu {
  li {
    padding: 0 0 8px 0;
    line-height: 23px;
    a {
      color: #a2a4a8;
      padding: 0;
      &:before {
        content: '\f101';
        font-family: FontAwesome;
        font-size: 13px;
        margin-right: 10px;
      }
    }
    &.open a, &.open a:hover, &.open a:active, &.open a:focus, a:hover, a:active, a:focus {
      background: transparent;
      color: @crt_global_cl;
    }
  }
}

/* End Menu Quick Link */

/* Google Map */
.tz_map_overlay {
  background: rgba(229, 174, 73, 0.9) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  .transition(all 0.3s ease-in-out 0s);
}

.tz_google_map {
  height: 160px;
  overflow: hidden;
  .transition(all 0.3s ease-in-out 0s);
  .tz_map_button {
    position: absolute;
    top: 50%;
    .translateY(-50%);
    left: 0;
    right: 0;
    border: medium none;
    color: @white;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: inline-block;
    span {
      .transition(all 0.3s ease-in-out 0s);
    }
    i {
      font-size: 25px;
      margin-right: 10px;
    }
  }
  .close_map {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -40px;
  }
  .view_map {
    visibility: visible;
    opacity: 1;
  }
  &.active {
    .tz_map_overlay {
      top: 90%;
    }
    .close_map {
      visibility: visible;
      opacity: 1;
    }
    .view_map {
      visibility: hidden;
      opacity: 0;
    }
  }
}

/* Single Article */
.single_article {
  padding: 0 0 0 40px;
  .tz_image {
    margin-right: 50px;
    display: block;
    .box-shadow(10px 10px 0 @crt_global_cl);
  }
  .tz_title {
    font-weight: 700;
  }
  .moduleItemIntrotext {
    font-size: 13px;
    color: @color_666;
  }
  .tz_info {
    padding-top: 20px;
    padding-right: 0;
  }
  .tz_title {
    margin: 0 0 20px;
  }
  .tz-plazart-list {
    padding-top: 35px;
  }
}

/* Twitter*/
.latest_tweets {
  li {
    overflow: hidden;
    position: relative;
    margin-bottom: 35px;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    padding-left: 30px !important;
    .tweets_txt {
      color: #868686;
      font-weight: 300;
      line-height: 23px;
      padding: 0 !important;
    }
    a {
      color: #e4e4e4;
    }
    &:after {
      color: #e5ae49;
      content: "\f099";
      font-family: FontAwesome;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      left: 0;
      position: absolute;
      top: 4px;
    }
  }
}

/* End Twitter*/

/* Newsletter*/
.newsletter-footer {
  .mc-field-group {
    position: relative;
    margin-top: 35px;
    &:after {
      background: @crt_global_cl;
      border: medium none;
      border-radius: 0;
      height: 47px;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 47px;
      z-index: 1;
      content: '\f00c';
      font-family: FontAwesome;
      font-size: 16px;
      color: @white;
      line-height: 47px;
      text-align: center;
      cursor: pointer;
    }
  }
  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0;
  }
  input.email {
    border: medium none;
    border-radius: 0;
    .box-shadow(none);
    color: #868686;
    font-size: 14px;
    font-weight: 300;
    line-height: 23px;
    padding: 12px;
    width: 100%;
  }
}

/* End Newsletter*/
/* Breadcrumbs*/
.tz-breadcrumb {
  h1 {
    color: #eaeaea;
    display: block;
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
}

.breadcrumbs_style2 {
  .tz-breadcrumb {
    text-align: left;
    h1 {
      color: @white;;
      margin: 0 0 20px;
    }
    .page_heading {
      letter-spacing: 0.5px;
      line-height: 25px;
    }
  }
}

/* End Breadcrumbs*/

/* Tag*/
.k2TagCloudBlock {
  padding: 0 !important;
  a {
    background: #fafafa none repeat scroll 0 0;
    border: 1px solid #dedede;
    border-radius: 2px;
    color: @color_666;
    display: inline-block;
    float: left;
    margin: 0 10px 10px 0;
    padding: 4px 11px 6px !important;
    text-decoration: none;
    .transition(all 0.3s ease-in-out 0s);
    font-weight: 300;
    &:hover {
      background: @crt_global_cl !important;
      border-color: @crt_global_cl;
      color: @white;
    }
  }
}

/* End Tag*/

/* Categories K2*/
.k2ArchivesBlock,
.k2CategoriesListBlock {
  .level0 {
    padding: 0;
  }
  li {
    border-bottom: 1px solid #ededed;
    padding: 0 0 0 15px !important;
    position: relative;
    margin: 0 !important;
    &:before {
      color: @color_666;
      content: "\f0da";
      font-family: FontAwesome;
      font-size: 10px;
      left: 0;
      position: absolute;
      top: 18px;
    }
    &:hover,
    &.activeCategory {
      &:before, a {
        color: @crt_global_cl;
        font-weight: 300 !important;
      }
    }
    a {
      padding: 14px 0;
      display: block;
    }
  }
}

/* Search Shop */
.filter-price,
.search {
  .hikashop_filter_main_div {
    position: relative;
    > form {
      > div {
        float: none !important;
      }
    }
  }
}

.search {
  .hikashop_filter_main_div {
    input[type="text"] {
      width: 100% !important;
      background: @white;
      .box-shadow(none);
      border: 1px solid #dedede;
      color: #111111;
      font-style: italic;
      font-weight: 300;
      line-height: 24px;
      padding: 9px 45px 10px 15px;
    }
    .hikashop_filter_button_inside {
      bottom: 0;
      line-height: 40px;
      margin: 0 !important;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 50px;
      color: transparent;
      background: @crt_global_cl;
      a {
        color: transparent;
        position: relative;
        z-index: 1;
      }
      &:after {
        content: '\f002';
        font-family: FontAwesome;
        color: @white;
        font-size: 16px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }
  }
}

.filter-price {
  .ui-slider {
    margin-bottom: 40px !important;
  }
  .hikashop_filter_button_inside {
    bottom: 0;
    position: absolute;
    right: 0;
    margin: 0 !important;
    background: @crt_title_cl;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 3px 26px 5px;
    .transition(all 0.3s ease-in-out 0s);
    &:hover {
      background: @crt_global_cl;
    }
    a {
      color: @white;
    }
  }
  .hikashop_filter_cursor_span,
  .hikashop_filter_title {
    color: @color_666;
    font-weight: 300;
    line-height: 24px;
    padding-top: 7px;
    text-align: left;
  }
}

.filter-categories {
  .hikashop_filter_title,
  br {
    display: none;
  }
  .hikashop_filter_checkbox {
    display: block;
    margin: 0 0 12px;
    padding: 0 0 14px 22px;
    position: relative;
    border-bottom: 1px solid #ededed;
    a {
      float: right;
    }
    label {
      font-weight: 300;
      color: @color_666;
      line-height: 24px;
      margin: 0;
    }
    input[type="radio"] {
      position: absolute;
      left: 0;
      top: 7px;
      margin: 0;
    }
  }
}

/* Price Table*/
.tz_price_tables {
  .tz-item {
    padding-bottom: 30px;
  }
  &.style-1 {
    .tz_price_table {
      &:hover {
        .tz_button {
          color: @white;
          background: @crt_title_cl;
          border-color: @crt_title_cl;
        }
      }
      .border {
        border: 1px solid #f3f3f3;
        > * {
          padding: 0 15px;
        }
      }
      .tz_top {
        background: #fdfdfd;
        border-bottom: 1px solid #f3f3f3;
        padding-top: 30px;
        padding-bottom: 18px;
      }
      .tz_bottom {
        background: #fdfdfd;
        padding-top: 11px;
        padding-bottom: 5px;
        color: @crt_title_cl;
        font-weight: 700;

      }
      .tz_title {
        letter-spacing: 0.5px;
        margin: 0 0 20px;
      }
      i {
        font-size: 41px;
        color: #333333;
      }
      .tz_desc {
        background: @white;
        border-bottom: 1px solid #f3f3f3;
        ul {
          margin: 0;
          padding: 22px 0 35px;
          li {
            font-weight: 300;
            letter-spacing: 0.5px;
            line-height: 24px;
            list-style: outside none none;
            padding: 9px 0;
          }
        }
      }
      .tz_button {
        background: @crt_global_cl;
        display: block;
        padding: 13px;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }
  &.style-2 {
    .tz_price_table {
      border: 1px solid #3a3a3a;
      padding: 27px 11px 25px;
      .transition(all 0.3s ease-in-out 0s);
      &:hover {
        border-color: @crt_global_cl;
        .tz_title, i {
          color: @crt_global_cl;
        }
        .tz_button {
          background: @crt_global_cl;
        }
      }
    }
    .tz_top {
      padding: 0 0 21px;
    }
    i {
      color: @crt_title_cl;
      font-size: 41px;
      transition: all 0.3s ease-in-out 0s;
    }
    .tz_title {
      color: @crt_title_cl;
      font-size: 27px;
      font-weight: 700;
      margin: 13px 0 0;
      padding: 0;
      transition: all 0.3s ease-in-out 0s;
    }
    .tz_price {
      border-color: #dfdfdf;
      border-style: solid;
      border-width: 1px 1px 0;
      padding: 13px 0 9px;
      font-weight: 700;
      color: @crt_title_cl;
    }
    .tz_desc {
      border-color: #dfdfdf;
      border-style: solid;
      border-width: 0 1px 1px;
      ul {
        margin: 0;
        padding: 0 20px;
        li {
          border-top: 1px solid #f1f1f1;
          font-weight: 300;
          line-height: 24px;
          list-style: outside none none;
          padding: 15px 0;
        }
      }
    }
    .tz_button {
      margin-top: 25px;
    }
  }
  .pricing-footer-iner {
    position: relative;
    display: inline-block;
    margin-right: 45px;
  }
  .pricing-price {
    font-size: 50px;

  }
  .pricing-currency {
    font-size: 16px;
    left: 100%;
    position: absolute;
    top: 3px;
  }
  .pricing-period {
    bottom: 15px;
    font-size: 12px;
    left: 100%;
    position: absolute;
  }
}

.tz_carousel_center {
  margin: -76px 0;
  padding: 76px 0;
  overflow: hidden;
}

.ui-tooltip {
  display: none;
}

/* Team */
.tz_teams {
  .tz-item {
    padding-bottom: 33px;
    &:hover {
      .tz_team {
        .tz_content,
        .tz_image {
          border-color: @crt_global_cl;
        }
      }
      .tz_content {
        .tz_title, .tz_job, .tz_title a {
          color: @crt_global_cl;
        }
      }
    }
  }
  .tz_team {
    padding: 0 0 65px 18px;
    position: relative;
    .tz_image {
      background: @white;
      border: 10px solid @crt_title_cl;
      position: relative;
      z-index: 1;
      .transition(all 0.3s ease-in-out 0s);
    }
    .tz_content {
      position: absolute;
      top: 65px;
      left: 0;
      display: block;
      bottom: 0;
      right: 18px;
      border: 1px solid @crt_title_cl;
      .transition(all 0.3s ease-in-out 0s);
    }
    .tz_info {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 13px 17px;
      .tz_title {
        font-size: 14px;
        color: @crt_title_cl;
        margin: 0;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        .transition(all 0.3s ease-in-out 0s);
        a {
          .transition(all 0.3s ease-in-out 0s);
        }
      }
      .tz_job {
        .transition(all 0.3s ease-in-out 0s);
        font-size: 12px;
        color: @crt_title_cl;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
      }
    }
  }
}

//Plus Gallery

#pgzoomview a:hover {
  background-color: @crt_global_cl !important;
}

.plusgallery {
  .folio {
    .image a {
      color: @crt_global_cl;
    }
    .effect-folio:hover .tz_info {
      box-shadow: 0 -4px 0 @crt_global_cl inset;
    }
  }
  .elegant {
    .elegant-info a:hover {
      color: @crt_global_cl;
    }
  }
  .black_white {
    .black_white-overlay {
      background: rgba(229, 174, 73, 0.3);
      border-color: rgba(229, 174, 73, 0.8);
    }
  }
  .everline {
    .effect-everline:hover .tz_info {
      background: @crt_global_cl;
    }
  }
  .lania {
    .info-title {
      background: @crt_global_cl;
    }
  }
  .mirror {
    .back {
      background: @crt_global_cl;
    }
    .view a:hover {
      color: @crt_global_cl;
    }
  }
  .pinme {
    .effect-pinme:hover .tz_info {
      .box-shadow(0 -4px 0 @crt_global_cl inset);
    }
  }
  .rolly {
    .rolly-overlay {
      background: rgba(229, 174, 73, 0.6);
    }
  }
  .interiart {
    img {
      position: relative;
    }
    .effect-interiart {
      overflow: hidden;
      position: relative;
      &:hover {
        &::after {
          background: rgba(63, 63, 63, 0.5);
        }
        .interiart-btn {
          a {
            .scale(1);
            opacity: 1;
          }
        }
        .interiart-info {
          h3 {
            .translateY(30px);
            opacity: 1;
          }
        }
      }
      &:after {
        content: '';
        background: rgba(63, 63, 63, 0);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .transition(all 0.3s ease 0s);
      }
    }
    .tz_info {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      .translateY(-50%);
      z-index: 1;
    }
    .interiart-info {
      h3 {
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 20px;
        text-transform: uppercase;
        .transition(all 0.3s ease 0s);
        margin: 0;
        opacity: 0;
        .translateY(0);
      }
    }
    .interiart-btn {
      a {
        background: url("../../../images/bg-zoom.png") no-repeat;
        border-radius: 50%;
        box-shadow: none;
        display: inline-block;
        height: 50px;
        line-height: 33px;
        opacity: 0;
        top: 50%;
        .scale(0);
        .transition(all 0.3s linear 0s);
        width: 50px;
      }
    }
  }
}










