.hikashop_products {
  ul.thumbnails {
    margin: 0;
  }
}

.hikashop_products_listing {
  .hikashop_product {
    .tooltip.top {
      margin-bottom: 10px;
      .tooltip-inner {
        line-height: 24px;
      }
    }
    &.img_title {
      padding-top: 0;
      padding-bottom: 0;
      cursor: pointer;
      .hikashop_container {
        padding-bottom: 40px;
      }
      .hikashop_product_quantity_div {
        display: inline-block;
      }
      .hikashop_product_desc {
        display: none;
      }
      .hikashop_subcontainer {
        padding: 0;
        margin: 0;
        border-radius: 0 !important;
        border-color: rgba(229, 174, 73, 0.2);
        position: relative;
        z-index: 1;
        .hikashop_product_image {
          position: relative;
          overflow: hidden;
        }
        &:hover {
          &:after {
            border-width: 5px;
            border-color: rgba(229, 174, 73, 0.5);
            background: rgba(229, 174, 73, 0.1);
            z-index: 1;
          }
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 0 solid rgba(229, 174, 73, 0.2);
          .transition(all 0.3s ease-in-out 0s);
          z-index: 0;
        }
        .tz_shop_link_item,
        .add_to_cart_listing,
        .tz_shop_item_info {
          z-index: 1;
          position: static;
          > * {
            z-index: 2;
            position: relative;
          }
        }
        .tz_shop_item_info {
          padding: 36px 0 30px;
        }
        .view_detail {
          position: absolute;
          left: 0;
          right: 0;
          .transition(all 0.3s ease-in-out 0.3s);
          bottom: -40px;
          z-index: 2;
        }
        .tz_shop_link_item {
          background-color: @crt_global_cl;
          border-radius: 20px;
          color: @white;
          display: inline-block;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 2px;
          line-height: 24px;
          padding: 7px 26px;
          text-transform: uppercase;
        }
        .title_product {
          font-weight: 400;
          letter-spacing: 1px;
          line-height: 24px;
          text-transform: uppercase;
          font-size: 14px;
          margin: 0;
        }
        .hk-rating {
          margin-top: 12px;
          line-height: 1em;
          a {
            font-size: 15px;
          }
        }
        .hikashop_product_price_full {
          margin-top: 10px;
        }
        .hikashop_product_price_before_discount {
          margin-right: 8px;
        }
        .hikashop_product_price_before_discount,
        .hikashop_product_price {
          font-size: 18px;
          font-weight: 400;
        }
        .hikashop_product_price_before_discount {
          color: #b5b5b5;
          text-decoration: line-through;
        }
        .hikashop_product_price {
          color: @crt_global_cl;
        }
        &:hover {
          .add_to_cart_listing {
            .hikashop_cart_button {
              .scale(1);
            }
          }
          .view_detail {
            bottom: 0;
          }
        }
      }
    }
    &.img_pane {
      padding-top: 0;
      padding-bottom: 0;
      .hikashop_container {
        padding-bottom: 20px;
        margin-bottom: 20px !important;
        border-bottom: 1px solid #ededed;
      }
      .hikashop_img_pane_panel {
        width: 100%;
        padding-left: 15px;
        .hikashop_product_name {
          color: @crt_title_cl;
          font-size: 13px;
          font-weight: 400;
          line-height: 24px;
          margin: 0 0 2px;
          padding: 0;
          a {
            color: @crt_title_cl;
            padding: 0;
          }
        }
        .hikashop_product_price_full {
          font-size: 13px;
        }
        .hikashop_product_price {
          color: @crt_global_cl;
        }
        .hikashop_product_price_before_discount {
          text-decoration: line-through;
          margin-right: 3px;
        }
        .state-empty, .state-full {
          font-size: 14px;
          padding: 0;
        }
      }
    }
  }
  &.layout_list {
    .tooltip {
      display: none !important;
    }
    .hikashop_product {
      width: 100%;
      .hikashop_subcontainer {
        &:hover {
          .view_detail {
            opacity: 1;
            visibility: visible;
            -webkit-transform: translateY(-50%) rotateX(0deg);
            -moz-transform: translateY(-50%) rotateX(0deg);
            -ms-transform: translateY(-50%) rotateX(0deg);
            -o-transform: translateY(-50%) rotateX(0deg);
            transform: translateY(-50%) rotateX(0deg);
          }
        }
      }
      .tz_img_title {
        padding: 24px 20px 28px;
      }
      .hikashop_product_desc {
        display: block;
      }
      .tz_shop_item_info,
      .hikashop_product_image {
        display: table-cell;
        vertical-align: top;
      }
      .hikashop_product_image {
        max-width: 260px;
      }
      .tz_shop_item_info {
        width: 70%;
        text-align: left;
        padding: 0 0 0 20px !important;
      }
      .hk-rating {
        margin: 0 !important;
      }
      .state-empty, .state-full {
        font-size: 14px;
        padding: 0;
      }
      .hikashop_product_price_full {
        margin-top: 5px;
      }
      .hikashop_product_desc {
        margin-top: 17px;
      }
      .hikashop_product_price_before_discount,
      .hikashop_product_price {
        font-size: 15px !important;
      }
      .add_to_cart_listing {
        position: relative !important;
        top: auto;
        margin: 23px 0 7px;
        .translateY(0);
        .hikashop_cart_button {
          .scale(1);
          width: auto;
          height: auto;
          border-radius: 20px;
          padding: 7px 21px 9px;
          letter-spacing: 0.5px;
          background: #c7c7c7;
          border-color: #c7c7c7;
          font-weight: 400;
          .transition(all 0.3s ease 0.3s);
          &:hover {
            background: @crt_global_cl;
            border-color: @crt_global_cl;
          }
          .tz_layout_list {
            display: inline-block;
            color: @white;
          }
          i {
            line-height: 1em;
            margin-right: 10px;
          }
        }
      }
      .view_detail {
        top: 50%;
        bottom: auto !important;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(-50%) rotateX(90deg);
        -moz-transform: translateY(-50%) rotateX(90deg);
        -ms-transform: translateY(-50%) rotateX(90deg);
        -o-transform: translateY(-50%) rotateX(90deg);
        transform: translateY(-50%) rotateX(90deg);
      }
    }
  }
}

.hikashop_products_listing {
  .img_title {
    .add_to_cart_listing {
      position: absolute !important;
      top: 50%;
      margin-top: -25%;
      .translateY(-50%);
      left: 0;
      right: 0;
      .hikashop_cart_button {
        height: 40px;
        width: 40px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: 1px solid @crt_global_cl;
        background: @crt_global_cl;
        margin: 0 5px;
        display: inline-block;
        position: relative;
        text-align: center;
        .scale(0);
        .transition(all 0.3s ease-in-out 0.3s);
        i {
          color: @white;
          line-height: 40px;
          margin: 0;
          padding: 0;
        }
      }
      .tz_layout_list {
        display: none;
      }
    }
  }
}

.hikashop_products_pagination,
.hikashop_products_listing_main .hikashop_filter_main {
  .chzn-container {
    &.chzn-container-active.chzn-with-drop .chzn-single > div > b {
      background-position: -18px 8px;
    }
    .chzn-single {
      padding: 4px 11px;
      line-height: 28px;
      border-radius: 1px;
      border: 1px solid #dedede;
      height: auto;
      background: @white;
      color: @color_666;
      font-weight: 300;
      .box-shadow(none);
      > div {
        width: 15px;
        right: 10px;
        > b {
          background-position: 0 8px;
        }
      }

    }
    .chzn-drop {
      border-color: #dedede;
      color: @color_666;
      .box-shadow(none);
    }
  }
}

.hikashop_module  .tz_top {
  display: none;
}

.hikashop_products_listing_main {
  .hikashop_filter_main {
    display: block;
    float: none !important;
    width: 100% !important;
  }
  .tz_top {
    border-color: #ededed;
    border-style: solid;
    border-width: 0 0 1px;
    margin-bottom: 40px;
    padding: 0 0 25px;
    &:after {
      clear: both;
      display: block;
      content: '';
    }
    .hikashop_filter_main_div {
      margin: 0 20px 0 0;
    }
    .hikashop_results_counter {
      border: 1px solid #dedede;
      border-radius: 1px;
      color: @color_666;
      line-height: 20px;
      padding: 9px 15px;
      font-weight: 300;
    }
    .view_list {
      margin-top: 10px;
      label {
        font-weight: 300;
      }
      a {
        font-size: 18px;
        line-height: 1em;
        margin-left: 7px;
        &:hover {
          color: @crt_global_cl;
        }
      }
    }
  }
}

.view-category {
  .tz_top {
    display: none;
  }
}

/* Vote */

div.hk-rating {
  position: relative;
  display: inline-block;
}

.hika_comment_listing_empty_stars,
.hika_comment_listing_full_stars,
.state-empty,
.state-full {
  background: none !important;
  font-size: 20px;
  float: left;
  padding-right: 4px;
  cursor: pointer;
}

.hika_comment_listing_empty_stars:before,
.state-empty:before {
  content: "\f006";
}

.hika_comment_listing_empty_stars,
.hika_comment_listing_full_stars,
.state-full,
.state-empty {
  font-family: FontAwesome;
  color: @crt_global_cl !important;
}

.hika_comment_listing_full_stars:before,
.state-full:before,
.state-full.state-hover:before {
  color: @crt_global_cl !important;
  content: "\f005";
}

/* Product Detail */
.hikashop_product_page {
  .hikashop_child_image {
    display: block;
    margin: auto;
  }
  .hikashop_submodules h2 {
    color: @crt_title_cl;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 25px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  .hikashop_product_name_main {
    h1 {
      color: @crt_title_cl;
      font-size: 20px;
      font-weight: 400;
      margin: 15px 0 8px;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
  .state-empty, .state-full {
    font-size: 14px;
  }
  .hikashop_product_main_image_thumb {
    padding-left: 100px;
    .hikashop_product_main_image_subdiv {
      border: 5px solid rgba(229, 174, 73, 0.5);
      position: relative;
      &:after {
        background: rgba(229, 174, 73, 0.1) none repeat scroll 0 0;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  #hikashop_product_right_part {
    .hikashop_product_description_main_mini {
      border-bottom: 1px solid #ededed;
      margin-bottom: 30px;
      padding-bottom: 39px;
    }
    .hikashop_product_price_full {
      color: @crt_global_cl;
      font-size: 25px;
      font-weight: 700;
      line-height: 25px;
      margin-bottom: 27px;
      .hikashop_product_price_before_discount {
        color: #b5b5b5;
        text-decoration: line-through;
        font-weight: 400;
        margin-right: 10px;
      }
    }
    .tz_info {
      margin-top: 30px;
    }
    .hikashop_product_vote_mini {
      margin-bottom: 10px;
    }
    .hikashop_add_wishlist,
    .hikashop_product_quantity_div {
      display: inline-block;
      i {
        display: none;
      }
      .hikashop_cart_button {
        background: #c7c7c7;
        color: @white;
        border-radius: 20px;
        font-weight: 400;
        line-height: 24px;
        padding: 7px 20px;
        margin: 0 5px;
        display: block;
        .transition(all 0.3s ease-in-out 0s);
        &:hover {
          background: @crt_global_cl;
        }
      }
    }
    .hikashop_product_stock_count {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
      color: #ff0000;
    }
    .hikashop_product_characteristics {
      margin: 0 0 30px 0;
      padding: 30px 0;
      border-bottom: 1px solid #ededed;
      tr {
        td:first-child {
          padding-right: 10px;
        }
      }
      .chzn-container {
        &.chzn-container-active.chzn-with-drop .chzn-single > div > b {
          background-position: -18px 8px;
        }
        .chzn-single {
          padding: 4px 11px;
          line-height: 28px;
          border-radius: 1px;
          border: 1px solid #dedede;
          height: auto;
          background: @white;
          color: @color_666;
          font-weight: 300;
          .box-shadow(none);
          span {
            margin: 0;
          }
          > div {
            width: 15px;
            > b {
              background-position: 0 8px;
            }
          }

        }
        .chzn-drop {
          border-color: #dedede;
          color: @color_666;
          .box-shadow(none);
        }
      }
    }
    .tz_info {
      > * {
        display: inline-block;
        margin-right: 10px;
      }
      .hikashop_product_tags {
        ul {
          display: inline-block;
          margin: 0;
          li {
            padding: 0;
            a {
              background: transparent;
              color: @color_666;
              font-weight: 300;
              font-size: 14px;
              padding: 0;
              &:hover {
                color: @crt_global_cl;
              }
            }
          }
        }
      }
    }

    .hikashop_product_quantity_main {
      border-bottom: 1px solid #ededed;
      padding-bottom: 30px;
      .tooltip {
        display: none !important;
      }
      .hikashop_product_quantity_input_div_regrouped,
      .hikashop_product_quantity_input_div_simplified,
      .hikashop_product_quantity_change_div_leftright,
      .hikashop_product_quantity_input_div_default {
        input {
          border: 1px solid #ededed;
          border-radius: 2px;
          color: #666;
          font-weight: 300;
          line-height: 24px;
          padding: 7px 10px;
          width: 70px;
        }
      }
      .hikashop_product_quantity_input_div_regrouped {
        position: relative;
        .add-on {
          position: absolute;
          right: 0;
          top: 0;
          a {
            background: #c7c7c7;
            color: @white;
            border: medium none;
            display: block;
            height: 20px;
            line-height: 20px;
            padding: 2px;
            text-align: center;
            width: 15px;
          }
        }
      }
    }
  }
  .hikashop_product_bottom_part.show_tabular {
    border-bottom: 2px solid rgba(229, 174, 73, 0.2);
    display: block;
    margin-bottom: 44px;
    margin-top: 59px;
    padding: 0 0 52px;

    .hikashop_tabs_ul {
      margin: 0 0 -1px;
      padding: 0 5px;
      li {
        padding: 0;
        margin: 0 2px -1px 0;
        display: inline-block;
        float: none;
        &:hover,
        &.active {
          a {
            background: @white;
            border-bottom-color: @white;
            z-index: 2;
            color: @crt_global_cl;
          }
        }
        a {
          cursor: pointer;
          display: inline-block;
          margin: 0 2px 0 0;
          padding: 13px 29px;
          line-height: 24px;
          font-weight: 500;
          border-width: 3px 1px 0;
          border-style: solid;
          border-color: @crt_global_cl rgba(229, 174, 73, 0.2) transparent rgba(229, 174, 73, 0.2);;
          background: @crt_global_cl;
          letter-spacing: 0.5px;
          border-radius: 0;
          text-transform: uppercase;
          .transition(all 0.3s ease-in-out 0s);
        }
      }
    }
    .hikashop_tabs_content {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
      border: 1px solid rgba(229, 174, 73, 0.2);
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      padding: 24px 28px 13px;
    }
  }
  #hikashop_comment_form {
    .hikashop_listing_comment {
      color: @crt_title_cl;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .hika_comment_listing_stars {
      float: right;
      &:after {
        clear: both;
        content: '';
        display: block;
      }
    }
    .hikashop_comment_textarea,
    .hikashop_comment_form_mail input,
    .hikashop_comment_form_name input {
      border-style: solid;
      border-width: 0 0 1px 0;
      border-color: #e3e3e3;
      width: 100%;
      padding: 15px 0;
      margin-bottom: 30px;
    }
    .btn-send-commnet {
      background: #c7c7c7;
      border-radius: 20px;
      color: #ffffff;
      display: inline-block;
      font-weight: 400;
      line-height: 24px;
      padding: 7px 20px;
      text-decoration: none;
      .transition(all 0.3s ease-in-out 0s);
      border: none;
      .box-shadow(none);
      margin-bottom: 10px;
      &:hover {
        background: @crt_global_cl;
      }
    }
    .hika_comment_listing {
      padding: 10px 15px;
    }
    .hika_vote_listing_username {
      color: @crt_title_cl;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .hika_comment_date {
      color: #b5b5b5;
      font-size: 14px;
      font-style: italic;
      font-weight: 300;
    }
    .hika_comment_listing_content {
      color: @color_666;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.5px;
      line-height: 25px;
      margin-top: 5px;
    }
    .hikashop_form_comment_let_comment {
      color: @crt_title_cl;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .hika_comment_listing_empty_stars, .hika_comment_listing_full_stars {
      font-size: 14px;
    }
  }
  .lSSlideWrapper {
    border: 5px solid rgba(229, 174, 73, 0.5);
  }
  .lightSlider {
    li {
      padding: 0;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(229, 174, 73, 0.1);
      }
    }
  }
  .lSPager {
    li {
      border: 1px solid rgba(229, 174, 73, 0.2);
      border-radius: 0 !important;
      padding: 0;
      &:hover,
      &.active {
        border: 1px solid rgba(229, 174, 73, 0.5);
      }
    }
  }
}

/* Cart */
#hikashop_cart_listing {
  .hikashop_showcart_infos {
    margin-top: 30px;
    .inputbox {
      border: 1px solid #d3ced2;
      box-sizing: border-box;
      line-height: 1;
      outline: 0 none;
      padding: 6px 6px 5px;
      width: 100%;
    }
  }
  .hikashop_showcart_infos,
  .hikashop_cart_products {
    th {
      &.hikashop_cart_name_title {
        text-align: left;
      }
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      text-align: center;
      color: #212121;
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding: 14px 20px;
      text-transform: uppercase;
    }
    td {
      vertical-align: middle;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px;
      &.hikashop_show_cart_quantity_td {
        input {
          width: 50px;
          display: inline-block;
          text-align: center;
          border: 1px solid #e0e0e0;
          padding: 9px 15px;
          border-radius: 2px;
        }
        .hikashop_cart_product_quantity_refresh {
          display: inline-block;
        }
      }
      .hikashop_product_price_before_discount {
        text-decoration: line-through;
        color: #b5b5b5;
        display: block;
      }
      .hikashop_product_price {
        color: @crt_title_cl;
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        display: block;
      }
      &.total_price {
        .hikashop_product_price {
          color: @crt_global_cl;
        }
      }
      .hikashop_no_print {
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        text-decoration: none;
      }
      &.hika_show_cart_total_price {
        color: @crt_global_cl;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        font-family: "Montserrat", sans-serif;
      }
    }
    .hika_show_cart_total .hikashop_cart_button {
      padding: 10px 15px;
      margin: 0;
    }

  }
  .hikashop_cart_products {
    margin-bottom: 30px;
  }
  .hikashop_cart_button {
    background: @crt_title_cl;
    border: medium none;
    border-radius: 25px;
    color: @white;
    font-weight: 300;
    line-height: 24px;
    padding: 10px 30px;
    margin-right: 10px;
    .transition(all 0.3s ease-in-out 0s);
    width: auto;
    display: inline-block;
    &:hover {
      background: @crt_global_cl;
    }
  }
}

/*Check Out*/
.hikashop_checkout_page {

  .hikashop_checkout_cart {
    th {
      &.hikashop_cart_name_title {
        text-align: left;
      }
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      text-align: center;
      color: #212121;
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding: 14px 20px;
      text-transform: uppercase;
    }
    td {
      vertical-align: middle;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px;

      .hikashop_checkout_cart_subtotal {
        color: @crt_title_cl;
      }

      &.hikashop_cart_subtotal_value,
      &.hikashop_cart_total_value {
        color: @crt_title_cl;
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
      }
      .hikashop_checkout_cart_final_total {
        color: @crt_global_cl;

      }
      &.hikashop_cart_product_quantity_value {
        input {
          width: 50px;
          display: inline-block;
          text-align: center;
          border: 1px solid #e0e0e0;
          padding: 9px 15px;
          border-radius: 2px;
        }
        .hikashop_cart_product_quantity_delete,
        .hikashop_cart_product_quantity_refresh {
          display: inline-block;
        }
      }
      .hikashop_product_price_before_discount {
        text-decoration: line-through;
        color: #b5b5b5;
        display: block;
      }
      .hikashop_product_price {
        color: @crt_title_cl;
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        display: block;
      }
      &.total_price {
        .hikashop_product_price {
          color: @crt_global_cl;
        }
      }
      .hikashop_no_print {
        font-family: "Montserrat", sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        text-decoration: none;
      }
      &.hika_show_cart_total_price {
        color: @crt_global_cl;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        font-family: "Montserrat", sans-serif;
      }

    }
  }

  h3 {
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 20px;
  }
  .hikashop_checkout_login {
    .control-group {
      margin-bottom: 30px;
      input[type="checkbox"] {
        margin-left: 0;
        position: relative;
      }
    }
    label {
      color: @crt_title_cl;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      padding: 13px 0;
    }
    input[type='text'], input[type='password'], input[type='email'] {
      border: 1px solid #dadada;
      border-radius: 2px;
      color: @color_666;
      font-weight: 300;
      line-height: 24px !important;
      //margin-bottom: 20px;
      padding: 10px 15px;
      width: 95%;
    }
  }

  .chzn-container {
    width: 95% !important;
    .chzn-single {
      background: @white;
      border-radius: 2px;
      color: @color_666;
      font-weight: 300;
      line-height: 24px !important;
      //margin-bottom: 20px;
      padding: 10px 15px;
      border: 1px solid #dadada;
      .box-shadow(none);
      display: block;
      height: auto;
      div b {
        background-position: 0 12px;
      }
    }
  }

  .chzn-container-active.chzn-with-drop .chzn-single div b {
    background-position: -16px 12px;
  }
  #hikashop_checkout_coupon {
    padding: 30px 0;
    input {
      border: 1px solid #dadada;
      border-radius: 2px;
      color: @color_666;
      font-weight: 300;
      line-height: 24px !important;
      margin-right: 10px;
      padding: 10px 15px;
    }
  }
  .hikashop_payment_methods,
  .hikashop_shipping_methods {
    margin-top: 30px;
    .hikashop_shipping_group,
    .hikashop_payment_group {
      padding: 45px 70px;
      background: #ededed;
    }
    input[type="radio"] {
      margin-right: 10px;
    }
    label {
      color: @crt_title_cl;
      font-family: "Montserrat", sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      padding: 13px 0;
    }
  }
}

.hikashop_wizardbar {
  ul {
    margin-bottom: 30px;
    li {
      display: inline-block;
      padding: 10px;
      &.hikashop_cart_step_current {
        color: @crt_global_cl;
        .badge {
          background: @crt_global_cl;
        }
      }
      &:last-child:after {
        content: none;
      }
      &:after {
        content: '\f178';
        font-family: FontAwesome;
        margin-left: 10px;
      }
    }
  }
}

#form-login-password,
#form-login-username {
  .controls {
    position: relative;
  }
  .tz_help,
  .add-on {
    position: absolute;
    top: 13px;
  }
  .add-on {
    left: 10px;
  }
  .tz_help {
    right: 10px;
  }
  input {
    width: 100% !important;
    padding-left: 30px;
    padding-right: 20px;
  }
}

#form-login-remember {
  margin: 0 0 15px 0;
  label {
    padding: 0;
  }
}